import { Map } from "immutable";
import actions from "./actions";
import { AnyAction } from "@reduxjs/toolkit";
const { fromJS } = require("immutable");

const initialState = fromJS(
  Map({
    longLeaves: [],
  })
);

export interface Action {
  type: string;
  payload?: any;
  event?: string;
}

const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case actions.FETCH_LONG_LEAVE_REQUESTS_SUCCESS:
      return state.setIn(["longLeaves"], action.payload);

    case actions.SAVE_LONG_LEAVE_REQUEST_SUCCESS:
      let longLeavesIndex = 0;
      longLeavesIndex = state.toJS().longLeaves.length;
      return state.setIn(["longLeaves", "data", longLeavesIndex], action.payload);

    case actions.UPDATE_LONG_LEAVE_REQUEST_SUCCESS:
      let longLeaveIndex = 0;
      longLeaveIndex = state
        .toJS()
        .longLeaves?.data?.findIndex(
          (longLeave: any) => longLeave.id === action.payload.id
        );
      return state.setIn(["longLeaves", "data", longLeaveIndex], action.payload);
    default:
      return state;
  }
};

export default reducer;
