const LongLeaveactions = {
  FETCH_LONG_LEAVE_REQUESTS: "FETCH_LONG_LEAVE_REQUESTS",
  FETCH_LONG_LEAVE_REQUESTS_SUCCESS: "FETCH_LONG_LEAVE_REQUESTS_SUCCESS",
  SAVE_LONG_LEAVE_REQUEST: "SAVE_LONG_LEAVE_REQUEST",
  SAVE_LONG_LEAVE_REQUEST_SUCCESS: "SAVE_LONG_LEAVE_REQUEST_SUCCESS",
  UPDATE_LONG_LEAVE_REQUEST: "UPDATE_LONG_LEAVE_REQUEST",
  UPDATE_LONG_LEAVE_REQUEST_SUCCESS: "UPDATE_LONG_LEAVE_REQUEST_SUCCESS",

  fetchLongLeaveRequests: (
    // page: number,
    // pageSize: number,
    resolve: (token: string) => void,
    reject: (value: string) => void,
  ) => ({
    type: LongLeaveactions.FETCH_LONG_LEAVE_REQUESTS,
    // page,
    // pageSize,
    resolve,
    reject,
  }),

  saveLongLeaveRequest: (
    data: any,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: LongLeaveactions.SAVE_LONG_LEAVE_REQUEST,
    data,
    resolve,
    reject,
  }),

  updateLongLeaveRequest: (
    data: any,
    resolve: (value: string) => void,
    reject: (value: string) => void
  ) => ({
    type: LongLeaveactions.UPDATE_LONG_LEAVE_REQUEST,
    data,
    resolve,
    reject,
  }),
};

export default LongLeaveactions;
