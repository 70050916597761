import LongLeaveSaga from "./sagas";
import LongLeaveReducer from "./reducers";
import LongLeaveActions from "./actions";

export const longLeaveReducer = {
  longLeaveReducer: LongLeaveReducer,
};

export const longLeaveSaga = [LongLeaveSaga()];

export { LongLeaveActions };
