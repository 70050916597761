import jwt_decode from "jwt-decode";
import { JWTToken } from "./interface/Utils.interface";
import { TimeSlotType } from "pages/timeSlot/timeSlot.interface";
import {
  dayToIndex,
  days,
  daysLabel,
  monthsLabel,
  roleNames,
  tokens,
} from "./Constants";
import dayjs from "dayjs";
import moment from "moment-timezone";

export const hasTokenExpired = (): boolean | undefined => {
  const token: string = localStorage.getItem("token") as string;
  if (token) {
    const decodedToken: JWTToken = jwt_decode(token || "");
    const expireTime: number = decodedToken.exp * 1000;
    const currentTime: number = Date.now();
    const isExpired: boolean = expireTime < currentTime;
    return isExpired;
  } else {
    return false;
  }
};

export const getToken = (): boolean | undefined => {
  const token: string = localStorage.getItem("token") as string;
  if (token) {
    return true;
  } else {
    return false;
  }
}

export const format12Hrs = (hr: number, min: number) => {
  if (hr >= 0 && hr <= 24 && min >= 0 && min <= 60) {
    let AMorPM = "AM";
    if (hr >= 12) AMorPM = "PM";
    hr = hr % 12;
    return (
      (hr < 10 ? (hr === 0 ? 12 : hr.toString().padStart(2, "0")) : hr) +
      " : " +
      (min < 10 ? min.toString().padStart(2, "0") : min) +
      " " +
      AMorPM
    );
  }
};

export const formatTimeSlots = (timeSlots: TimeSlotType[]) => {
  return timeSlots.map((timeSlot: TimeSlotType) => {
    return {
      value: timeSlot.id,
      class_type: timeSlot.class_type,
      class_number: timeSlot.class_number,
      show:
        format12Hrs(
          parseInt(timeSlot.start_time.split(":")[0]),
          parseInt(timeSlot.start_time.split(":")[1])
        ) +
        " - " +
        format12Hrs(
          parseInt(timeSlot.end_time.split(":")[0]),
          parseInt(timeSlot.end_time.split(":")[1])
        ),
    };
  });
};

export const convertTo12HourFormat = (time24: any) => {
  const [hour24, minute] = time24.split(':');
  const hour = parseInt(hour24);
  const period = hour >= 12 ? 'PM' : 'AM';
  const hour12 = hour % 12 || 12;
  return `${hour12}:${minute} ${period}`;
};

export const formatTimeSlot = (timeslot: any) => {
  if (!timeslot || timeslot.length === 0) return "Select Time Slot";
  const { start_time, end_time } = timeslot[0];
  const startTime12 = convertTo12HourFormat(start_time);
  const endTime12 = convertTo12HourFormat(end_time);
  return `${startTime12} - ${endTime12}`;
};

export const convertTo24HourFormat = (time12h: string) => {
  const [time, modifier] = time12h.split(/(AM|PM)/i);
  let [hours, minutes] = time.trim().split(":").map(Number);

  if (modifier.toUpperCase() === "PM" && hours !== 12) hours += 12;
  if (modifier.toUpperCase() === "AM" && hours === 12) hours = 0;

  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;
}

export const filterTerm = (
  term: string,
  items: Record<string, string>[]
): Record<string, string>[] => {
  return items.filter((item) => {
    return Object.keys(item).some((key) => {
      if (typeof item[key] === "string") {
        return item[key].toString().toLowerCase().includes(term.toLowerCase());
      } else {
        return false;
      }
    });
  });
};

export const formatDisplayTime = (date: Date, showWeekDay: boolean = true) => {
  return ` ${showWeekDay ? `${daysLabel[date.getDay()]},` : ``
    } ${date.getDate()} ${monthsLabel[date.getMonth()]} ${date.getFullYear()}`;
};

export const capitalizeSentence = (sentence: string): string => {
  return sentence.replace(/(^\w{1})|(\s{1}\w{1})/g, (match: string) =>
    match.toUpperCase()
  );
};

export const permissionData = (decodedToken: JWTToken): Array<string> => {
  let permissions: Array<string> = decodedToken?.Permissions;
  return permissions;
};

export const hasPermission = (permission: string): Boolean => {
  const decodedToken: JWTToken = jwt_decode(
    localStorage.getItem("token") || ""
  );
  let permissions: Array<string> = decodedToken?.Permissions;
  permissions.push("*");

  return permissions.includes(permission);
};

export const isAdmin = () => {
  const decodedToken: JWTToken = jwt_decode(
    localStorage.getItem("token") || ""
  );
  const role = decodedToken.Role;
  return role.rolename === roleNames.SUPER_ADMIN;
};

export const isBranchAdmin = () => {
  const decodedToken: JWTToken = jwt_decode(
    localStorage.getItem("token") || ""
  );
  const role = decodedToken.Role;
  return role.rolename === roleNames.BRANCH_ADMIN;
};

export const isBranchAssociate = () => {
  const decodedToken: JWTToken = jwt_decode(
    localStorage.getItem("token") || ""
  );
  const role = decodedToken.Role;
  return role.rolename === roleNames.BRACH_ASSOCIATE;
};

export const isParent = () => {
  const decodedToken: JWTToken = jwt_decode(
    localStorage.getItem("token") || ""
  );
  const role = decodedToken.Role;
  return role.rolename === roleNames.PARENT;
};

export const isChild = () => {
  const decodedToken: JWTToken = jwt_decode(
    localStorage.getItem("token") || ""
  );
  const role = decodedToken.Role;
  return role.rolename === roleNames.CHILD;
};

export const isInstructor = () => {
  const decodedToken: JWTToken = jwt_decode(
    localStorage.getItem("token") || ""
  );
  const role = decodedToken.Role;
  return role.rolename === roleNames.INSTRUCTOR;
};
export const isBranchSelfService = () => {
  const decodedToken: JWTToken = jwt_decode(
    localStorage.getItem("token") || ""
  );
  const role = decodedToken.Role;
  return role.rolename === roleNames.BRANCH_SELF_SERVICE;
};
export const isCustomerSupportAdmin = () => {
  const decodedToken: JWTToken = jwt_decode(
    localStorage.getItem("token") || ""
  );
  const role = decodedToken.Role;
  return role.rolename === roleNames.CUSTOMER_SUPPORT_ADMIN;
};
export const isCustomerSupportUser = () => {
  const decodedToken: JWTToken = jwt_decode(
    localStorage.getItem("token") || ""
  );
  const role = decodedToken.Role;
  return role.rolename === roleNames.CUSTOMER_SUPPORT_USER;
};
export const getUserId = () => {
  const decodedToken: JWTToken = jwt_decode(
    localStorage.getItem("token") || ""
  );
  return decodedToken.id;
};

export const getFormattedFullName = (): string => {
  const token: string | null = localStorage.getItem("token");
  const decodedToken: JWTToken = jwt_decode(token || "");
  return `${decodedToken.UserDetail.first_name} ${decodedToken.UserDetail.last_name}`;
};

export const getFormattedRoleName = (): string => {
  const token: string | null = localStorage.getItem("token");
  const decodedToken: JWTToken = jwt_decode(token || "");
  return decodedToken.Role.rolename;
};

export const getFormattedBranchAddress = (): string => {
  const token: string | null = localStorage.getItem("token");
  const decodedToken: JWTToken = jwt_decode(token || "");
  return decodedToken.branchName;
}

export const getUsername = (): string => {
  const token: string | null = localStorage.getItem("token");
  const decodedToken: JWTToken = jwt_decode(token || "");
  return decodedToken.username || "";
};

export const getUserInfo = () => {
  const decodedToken: JWTToken = jwt_decode(
    localStorage.getItem("token") || ""
  );
  return decodedToken.UserDetail;
};

export const getInfo = () => {
  const decodedToken: JWTToken = jwt_decode(
    localStorage.getItem("token") || ""
  );
  return decodedToken;
};

export const getBranchId = () => {
  const decodedToken: JWTToken = jwt_decode(
    localStorage.getItem("token") || ""
  );
  return decodedToken.branch;
};

export const formatToIndex = (daysLabels: string[]) => {
  // const formatted = daysLabels.map((dayLabel: string) => {
  //   return days?.findIndex((s) => s.label === dayLabel);
  // });
  const formatted = daysLabels.map((dayLabel) => dayToIndex[dayLabel]);
  return formatted;
};

export const formatAndHideString = (str: string) => {
  const regex = /(\d{4})(?=\d)/g;
  const maskedPortion = "x".repeat(str.length - 4);

  const lastFourDigits = str.slice(-4);
  const formattedStr = maskedPortion.replace(regex, "$1 ");

  return formattedStr + lastFourDigits;
};

export const generateLetterImageURL = (
  firstName: string,
  lastName: string
): string => {
  const letter: string =
    firstName?.charAt(0).toUpperCase() + lastName?.charAt(0).toUpperCase();
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const canvasSize = 100;
  if (!context) {
    return "";
  }

  canvas.width = canvasSize;
  canvas.height = canvasSize;

  context.fillStyle = tokens.BG_LIGHT;
  context.fillRect(0, 0, canvasSize, canvasSize);

  context.font = `${canvasSize / 2}px Arial`;
  context.fillStyle = tokens.COLOR_NEUTRAL_N900;
  context.textAlign = "center";
  context.textBaseline = "middle";
  context.fillText(letter, canvasSize / 2, canvasSize / 2);

  const imageUrl = canvas.toDataURL();

  return imageUrl;
};

export const getFileExtension = (url: string): string => {
  const filename = url.substring(url.lastIndexOf("/") + 1);
  return filename.split(".").pop()?.toLowerCase() || "";
};

export const isImageExtension = (extension: string): boolean => {
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
  return imageExtensions.includes(extension.toLowerCase());
};

export function getFirstNWords(text: any, n: any) {
  const words = text.split(" ");
  if (words.length <= n) {
    return text;
  }
  return words.slice(0, n).join(" ") + (words.length > n ? "..." : "");
}

export function capitalizeFirstLetter(str: string) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

export const passwordPattern =
  /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;
export const passwordValidationMessage =
  "The password must consist of a minimum of 8 characters and  must contain at least one uppercase letter, one lowercase letter, one digit, and one special character.";

export const urlGenerator = (baseUrl: string, action: any) => {
  let url = baseUrl;
  if (
    action.page ||
    action.pageSiz ||
    action.search ||
    action.status ||
    action?.filters?.length > 0
  ) {
    url += `?`;
  }
  if (action.page && action.pageSize) {
    url += `page=${action.page}&pageSize=${action.pageSize}`;
  }
  if (action.search !== "undefined" && action.search && action.searchKey) {
    url += `&search=${action.search}&searchKey=${action.searchKey}`;
  }

  if (action?.filters?.length > 0) {
    url += `&filters=[${action.filters}]`;
  }
  if (action.status) {
    url += `&status=${action.status}`;
  }

  return url;
};

export const formatDateForTable = (date: string) => {
  const dateVar = new Date(date);
  return `${dateVar.getMonth() + 1}/${dateVar.getDate()}/${dateVar.getFullYear()}`;
};

export const formatDate = (date: string) => {
  return dayjs(date).format("MM-DD-YYYY");
}

export const formatUTCDateTZ = (date: any, time_zone: any, format: string = "MMMM D, YYYY") => {
  const timeZone = time_zone ? time_zone : moment.tz.guess();
  return moment(date).tz(timeZone).format(format)
}

export const formatUTCDateBillingTZ = (date: any, time_zone: any, format: string = "MMMM D, YYYY") => {
  return moment.utc(date).format(format)
}

export const formatDateForCSV = (date: string) => {
  const dateVar = new Date(date);
  return `${dateVar.getMonth() + 1}/${dateVar.getDate()}/
  ${dateVar.getFullYear()}`;
};

export const getUserAgent = () => {
  return window.navigator.userAgent;
};

export const getUserDeviceResolution = () => {
  return {
    width: window.screen.width,
    height: window.screen.height,
  };
};

export const isOnMobile = () => {
  return getUserDeviceResolution().width < 1024 ? true : false;
};

export const trimStringProperties = (obj: {
  [key: string]: any;
}): { [key: string]: any } => {
  const trimmedObj: { [key: string]: any } = {};

  for (const key in obj) {
    if (typeof obj[key] === "string") {
      trimmedObj[key] = obj[key].trim();
    } else {
      trimmedObj[key] = obj[key];
    }
  }

  return trimmedObj;
};

export const getNameFirstLetters = (): string => {
  const token: string | null = localStorage.getItem("token");
  const decodedToken: JWTToken = jwt_decode(token || "");
  return `${decodedToken.UserDetail?.first_name
    .charAt(0)
    .toUpperCase()}${decodedToken.UserDetail?.last_name
      .charAt(0)
      .toUpperCase()}`;
};

export const generateImageWithLetters = (letter: string): string => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const canvasSize = 100;
  if (!context) {
    return "";
  }

  canvas.width = canvasSize;
  canvas.height = canvasSize;

  context.fillStyle = tokens.BG_LIGHT;
  context.fillRect(0, 0, canvasSize, canvasSize);

  context.font = `${canvasSize / 2}px Arial`;
  context.fillStyle = tokens.COLOR_NEUTRAL_N900;
  context.textAlign = "center";
  context.textBaseline = "middle";
  context.fillText(letter, canvasSize / 2, canvasSize / 2);

  const imageUrl = canvas.toDataURL();

  return imageUrl;
};

export const formatAvailableDays = (days: any): any => {
  if (days) {
    if (days.length === 1) {
      return JSON.stringify(days[0].toString()).slice(1, -1);
    } else if (days.length === 2) {
      return days.join(",");
    }
  }
}

export const removeDuplicatesByProperties = <T>(arr: T[], ...props: string[]): T[] => {
  const uniqueSet = new Set<string>();
  return arr?.filter((item: any) => {
    const compositeKey = props?.map((prop: any) => item[prop]).join(' ');
    const isUnique = !uniqueSet.has(compositeKey);
    uniqueSet.add(compositeKey);
    return isUnique;
  });
};

export const changeDateToUTC = (date: any) => {
  try {
    let rawDate = date;
    let utcDate;

    if (/^\d{4}-\d{2}-\d{2}$/.test(rawDate)) {
      utcDate = new Date(`${rawDate}T00:00:00Z`);
    } else {
      utcDate = new Date(rawDate);
    }

    if (isNaN(utcDate.getTime())) {
      throw new Error("Invalid date");
    }

    const formattedDate = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: '2-digit',
      timeZone: 'UTC',
    }).format(utcDate);

    return formattedDate;
  } catch (error) {
    console.error(error);
    return "Invalid Date";
  }
}

export const getFixedUTCDateTime = (dateString: any, hour = 12) => {
  // Set the UTC time to avoid unintended timezone shifts
  const utcDate = new Date(`${dateString}T${hour.toString().padStart(2, "0")}:00:00Z`);
  return utcDate.toISOString();
};


export const extractHoursAsDecimal = (hoursString: string) => {
  if (!hoursString) return 0;

  if (hoursString.includes("hrs") || hoursString.includes("hr")) {
    const parts = hoursString.split(" ");
    let hours = 0;
    let minutes = 0;

    for (let i = 0; i < parts.length; i++) {
      if (parts[i] === "hrs" || parts[i] === "hr") {
        hours = parseFloat(parts[i - 1]);
      }
      if (parts[i] === "min" || parts[i] === "mins") {
        minutes = parseFloat(parts[i - 1]);
      }
    }

    if (minutes >= 30) {
      return Math.ceil(hours + minutes / 60);
    } else {
      return Math.floor(hours + minutes / 60);
    }
  }

  else if (hoursString.includes(":")) {
    const [hours, minutes] = hoursString.split(":").map(parseFloat);

    if (minutes >= 30) {
      return Math.ceil(hours + minutes / 60);
    } else {
      return Math.floor(hours + minutes / 60);
    }
  }

  else if (!isNaN(parseFloat(hoursString))) {
    return parseFloat(hoursString);
  }

  return 0;
};

export const decodedPrice = (encodedPrice: string) => {
  try {
    const decodedPrice = atob(encodedPrice);
    
    return parseFloat(decodedPrice).toFixed(2);
  } catch (error) {
    console.error('Error decoding price:', error);
    return null;
  }
}