import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
    [x: string]: string | number | Array<object> | object;
}

interface TrialClassReturnType {
    [x: string]: string
}

interface TimeSlotReturnType {
    [x: string]: string
}

const fetchTrialClasses = takeLatest(
    actions.FETCH_TRIAL_CLASSES,
    function* (
        action: AnyAction
    ): Generator<Effect, void, TrialClassReturnType> {
        try {
            const response = yield call(
                api.get,
                urlGenerator(`api/trialclass`, {
                    ...action,
                    status: action.status ? JSON.stringify(action.status) : null,
                }),
                false
            );
            yield put({
                type: actions.FETCH_TRIAL_CLASSES_SUCCESS,
                payload: response,
            });
            yield call(action.resolve, response);
        } catch (error) {
            yield put({ type: actions.FETCH_TRIAL_CLASSES_FAILED });
            yield call(action.reject, "reject");
        }
    }
);

const fetchTrialClassTimeSlots = takeLatest(
    actions.FETCH_TRIAL_CLASS_TIME_SLOTS,
    function* (
        action: AnyAction
    ): Generator<Effect, void, TimeSlotReturnType> {
        try {
            const response = yield call(
                api.get,
                urlGenerator(`api/trialclass/timeslots`, action),
                false
            );
            yield put({
                type: actions.FETCH_TRIAL_CLASS_TIME_SLOTS_SUCCESS,
                payload: response,
            });
            yield call(action.resolve, response);
        } catch (error) {
            yield put({ type: actions.FETCH_TRIAL_CLASS_TIME_SLOTS_FAILED });
            yield call(action.reject, "reject");
        }
    }
)

const updateTrialClass = takeLatest(
    actions.UPDATE_TRIAL_CLASS,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
        try {
            const response = yield call(
                api.put,
                `api/trialclass/${action.data.id}`,
                action.data
            );
            yield put({
                type: actions.UPDATE_TRIAL_CLASS_SUCCESS,
                payload: response,
            });
            yield call(action.resolve, response);
        } catch (error) {
            yield put({ type: actions.UPDATE_TRIAL_CLASS_FAILED });
            yield call(action.reject, "reject");
        }
    }
)

const fetchTrialClass = takeLatest(
    actions.FETCH_TRIAL_CLASS,
    function* (action: AnyAction): Generator<Effect, void, TrialClassReturnType> {
        try {
            const response = yield call(api.get, "api/trialclass/" + action.id, false);
            yield put({
                type: actions.FETCH_TRIAL_CLASS_SUCCESS,
                payload: response,
            });
            yield call(action.resolve, response);
        } catch (error: any) {
            yield put({ type: actions.FETCH_TRIAL_CLASSES_FAILED });
            yield call(action.reject, error.response);
        }
    }
)

const fetchTrialClassesNotes = takeLatest(
    actions.FETCH_TRIAL_CLASS_NOTE,
    function* (
        action: AnyAction
    ): Generator<Effect, void, TrialClassReturnType> {
        try {
            const trialId = action.data.trialId;
            const status = String(action.data.status);
            const url = urlGenerator(`api/trialclass/notes/${trialId}?status=${status}`, action);

            const response: TrialClassReturnType = yield call(api.get, url, false);
            yield put({
                type: actions.FETCH_TRIAL_CLASS_NOTE_SUCCESS,
                payload: response,
            });
            yield call(action.resolve, response);
        } catch (error) {
            yield put({ type: actions.FETCH_TRIAL_CLASS_NOTE_FAIL });
            yield call(action.reject, "reject");
        }
    }
);

export default function* saga() {
    yield all([fetchTrialClasses, updateTrialClass, fetchTrialClassTimeSlots, fetchTrialClass, fetchTrialClassesNotes]);
}
