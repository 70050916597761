import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
  [x: string]: string | number | Array<object> | object;
}

const fetchLongLeaveRequests = takeLatest(
  actions.FETCH_LONG_LEAVE_REQUESTS,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const url = action.page
        ? `/api/longLeave?page=${action.page}&pageSize=${action.pageSize}`
        : `api/longLeave`;
      const response = yield call(api.get, urlGenerator("/api/longLeave", action));
      yield put({
        type: actions.FETCH_LONG_LEAVE_REQUESTS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const saveLongLeaveRequest = takeLatest(
  actions.SAVE_LONG_LEAVE_REQUEST,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(api.post, `api/longLeave`, action.data, null);
      yield put({
        type: actions.SAVE_LONG_LEAVE_REQUEST_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "reject");
    }
  }
);

const updateLongLeaveRequest = takeLatest(
  actions.UPDATE_LONG_LEAVE_REQUEST,
  function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
    try {
      const response = yield call(
        api.put,
        `api/longLeave/${action.data.id}`,
        action.data
      );

      yield put({
        type: actions.UPDATE_LONG_LEAVE_REQUEST_SUCCESS,
        payload: response,
      });

      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "reject");
    }
  }
);
export default function* saga() {
  yield all([fetchLongLeaveRequests, saveLongLeaveRequest, updateLongLeaveRequest]);
}
