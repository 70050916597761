import { AnyAction } from "@reduxjs/toolkit";
import { takeLatest, put, call, all, Effect } from "redux-saga/effects";
import Api from "services/api";
import actions from "./actions";
import { urlGenerator } from "utils/Helpers";

const api = new Api();

interface ReturnTypes {
    [x: string]: string | number | Array<object> | object;
}

interface AppointmentReturnTypes {
    [x: string]: string;
}
const createAppointment = takeLatest(
    actions.CREATE_APPOINTMENT,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
        try {
            const response = yield call(api.post, `api/appointment`, action.data, null);
            yield put({
                type: actions.CREATE_APPOINTMENT_SUCCESS,
                payload: response,
            });
            yield call(action.resolve, response);
        } catch (error: any) {
            yield put({ type: actions.CREATE_APPOINTMENT_FAILED });
            yield call(action.reject, error.response);
        }
    }
);

const fetchAppointments = takeLatest(
    actions.FETCH_APPOINTMENTS,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
        try {
            const response = yield call(
                api.get,
                urlGenerator("api/appointment", { ...action, status: action.status ? JSON.stringify(action.status) : null }),
                false
            );
            yield put({
                type: actions.FETCH_APPOINTMENTS_SUCCESS,
                payload: response,
            });
            yield call(action.resolve, response);
        } catch (error: any) {
            yield put({ type: actions.FETCH_APPOINTMENTS_FAILED });
            yield call(action.reject, error.response);
        }
    }
);

const updateAppointment = takeLatest(
    actions.UPDATE_APPOINTMENT,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
        try {
            const response = yield call(
                api.put,
                `api/appointment/${action.data.id}`,
                action.data
            );
            yield put({
                type: actions.UPDATE_APPOINTMENT_SUCCESS,
                payload: response,
            });
            yield call(action.resolve, response);
        } catch (error) {
            yield put({ type: actions.UPDATE_APPOINTMENT_FAILED });
            yield call(action.reject, "reject");
        }
    }
);

const rescheduleOrCancelAppointment = takeLatest(
    actions.RESCHEDULE_CANCEL_APPOINTMENT,
    function* (action: AnyAction): Generator<Effect, void, ReturnTypes> {
        try {
            const response = yield call(
                api.put,
                `api/appointment/${action.payload.buttonId}/${action.payload.appointmentId}`,
                action.payload.data
            );
            yield put({
                type: actions.RESCHEDULE_CANCEL_APPOINTMENT_SUCCESS,
                payload: response,
            });
            yield call(action.resolve, response);
        } catch (error: any) {
            yield put({ type: actions.RESCHEDULE_CANCEL_APPOINTMENT_FAILED });
            yield call(action.reject, error.response);
        }
    }
);

const fetchAppointment = takeLatest(
    actions.FETCH_APPOINTMENT,
    function* (action: AnyAction): Generator<Effect, void, AppointmentReturnTypes> {
        try {
            const response = yield call(api.get, "api/appointment/" + action.id, false);
            yield put({
                type: actions.FETCH_APPOINTMENT_SUCCESS,
                payload: response,
            });
            yield call(action.resolve, response);
        } catch (error: any) {
            yield put({ type: actions.FETCH_APPOINTMENT_FAILED });
            yield call(action.reject, error.response);
        }
    }
)

const fetchAppointmentOfParent = takeLatest(
    actions.FETCH_PARENT_APPOINTMENT,
    function* (action: AnyAction): Generator<Effect, void, AppointmentReturnTypes> {
        try {
            const response = yield call(api.get, "api/appointment/parentAppointment/" + action.id, false);
            yield put({
                type: actions.FETCH_PARENT_APPOINTMENT_SUCCESS,
                payload: response,
            });
            yield call(action.resolve, response);
        } catch (error: any) {
            yield put({ type: actions.FETCH_PARENT_APPOINTMENT_FAILED });
            yield call(action.reject, error.response);
        }
    }
)

const fetchAppointmentNotes = takeLatest(
    actions.FETCH_APPOINTMENT_NOTE,
    function* (
        action: AnyAction
    ): Generator<Effect, void, any> {
        try {
            const appointmentId = action.data.appointmentId;
            const status = String(action.data.status);
            const url = urlGenerator(`api/appointment/notes/${appointmentId}?status=${status}`, action);

            const response: any = yield call(api.get, url, false);
            yield put({
                type: actions.FETCH_APPOINTMENT_NOTE_SUCCESS,
                payload: response,
            });
            yield call(action.resolve, response);
        } catch (error) {
            yield put({ type: actions.FETCH_APPOINTMENT_NOTE_FAILED });
            yield call(action.reject, "reject");
        }
    }
);


export default function* saga() {
    yield all([
        createAppointment,
        fetchAppointments,
        updateAppointment,
        rescheduleOrCancelAppointment,
        fetchAppointment,
        fetchAppointmentOfParent,
        fetchAppointmentNotes
    ]);
}