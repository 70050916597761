const actions = {
    FETCH_EMPLOYEE_DETAILS: "FETCH_EMPLOYEE_DETAILS",
    FETCH_EMPLOYEE_DETAILS_SUCCESS: "FETCH_EMPLOYEE_DETAILS_SUCCESS",
    FETCH_EMPLOYEE_DETAILS_FAILED: "FETCH_EMPLOYEE_DETAILS_FAILED",
    FETCH_ALL_ATTENDANCE_OF_EMPLOYEE: "FETCH_ALL_ATTENDANCE_OF_EMPLOYEE",
    FETCH_ALL_ATTENDANCE_OF_EMPLOYEE_SUCCESS: "FETCH_ALL_ATTENDANCE_OF_EMPLOYEE_SUCCESS",
    FETCH_ALL_ATTENDANCE_OF_EMPLOYEE_FAILED: "FETCH_ALL_ATTENDANCE_OF_EMPLOYEE_FAILED",
    FETCH_EMPLOYEE_ROLE: "FETCH_EMPLOYEE_ROLE",
    FETCH_EMPLOYEE_ROLE_SUCCESS: "FETCH_EMPLOYEE_ROLE_SUCCESS",
    FETCH_EMPLOYEE_ROLE_FAILED: "FETCH_EMPLOYEE_ROLE_FAILED",
    UPDATE_CHECKOUT: "UPDATE_CHECKOUT",
    UPDATE_CHECKOUT_SUCCESS: "UPDATE_CHECKOUT_SUCCESS",
    UPDATE_CHECKOUT_FAILED: "UPDATE_CHECKOUT_FAILED",
    ADD_TIME: "ADD_TIME",
    ADD_TIME_SUCCESS: "ADD_TIME_SUCCESS",
    ADD_TIME_FAILED: "ADD_TIME_FAILED",
    UPDATE_CHECKIN: "UPDATE_CHECKIN",
    UPDATE_CHECKIN_SUCCESS: "UPDATE_CHECKIN_SUCCESS",
    UPDATE_CHECKIN_FAILED: "UPDATE_CHECKIN_FAILED",

    fetchEmployeeDetails: (
        // page: number,
        // pageSize: number,
        resolve: (data: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.FETCH_EMPLOYEE_DETAILS,
        // page,
        // pageSize,
        resolve,
        reject
    }),

    fetchAllAttendanceOfEmployee: (
        page: number,
        pageSize: number,
        id: any,
        filter: any,
        resolve: (data: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.FETCH_ALL_ATTENDANCE_OF_EMPLOYEE,
        page,
        pageSize,
        id,
        filter,
        resolve,   
        reject,
    }),

    fetchEmployeeRole: (
        resolve: (data: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.FETCH_EMPLOYEE_ROLE,
        resolve,
        reject
    }),

    updateCheckoutTime: (
        data: any,
        resolve: (data: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.UPDATE_CHECKOUT,
        data,
        resolve,
        reject
    }),

    updateCheckinTime: (
        data: any,
        resolve: (data: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.UPDATE_CHECKIN,
        data,
        resolve,
        reject
    }),

    addTime: (
        data: any,
        resolve: (data: string) => void,
        reject: (value: string) => void,
    ) => ({
        type: actions.ADD_TIME,
        data,
        resolve,
        reject
    })
}

export default actions;