// PaymentForm.tsx
import React, {
  ChangeEvent,
  ChangeEventHandler,
  useEffect,
  useState,
} from "react";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import stripePromise from "services/stripe";
import { Space, Form, Row, message, Col, Select, Checkbox, Card } from "antd";
import { AButton, AInput, AText, ATitle } from "components";
import { useForm } from "antd/es/form/Form";
import Api from "services/api";
import ASelect from "components/Select";
import { PaymentCardProps } from "pages/billing&payments/components/PaymentCards";
import FormItem from "antd/es/form/FormItem";
import axios, { AxiosError } from "axios";
import { CouponType } from "pages/coupons/coupon.interface";
import { set } from "immutable";
import { dailyCamp, states } from "utils/Constants";
import payment from "../../../assets/images/payment.webp";

interface PaymentFormProps {
  newChild?: any;
  selectedPlans: string[];
  onFinish: (
    paymentMethod: string,
    coupons?: string[],
    billingAddress?: any,
    branch_id?: any,
    course_id?: any,
    defaultPaymentCard?: any,
    referredBy?: any
  ) => void;
  admissionFee?: any;
  monthlyFee?: any;
  studentCount?: any;
  formValues?: any;
  userDetails?: any;
  id?: any;
  parent_id?: any;
  campForm?: any;
  dailyCamp?: any;
  selectedDates?: any;
  campToMonth?: any;
  programType?: any,
  courseFee?: any
}

interface Plan {
  amount: any;
}
interface Object extends Plan { }

const PaymentForm = ({
  selectedPlans,
  onFinish,
  newChild,
  admissionFee,
  monthlyFee,
  studentCount,
  formValues,
  userDetails,
  id,
  parent_id,
  campForm,
  dailyCamp,
  selectedDates,
  campToMonth,
  programType,
  courseFee
}: PaymentFormProps) => {
  const stripe = useStripe();
  const [cardInput, setCardInput] = useState<boolean>(true);
  const elements = useElements();
  const [form] = useForm();
  const api = new Api();
  const [plans, setPlans] = useState<Object[]>([]);
  const [cards, setCards] = useState<PaymentCardProps[] | any>([]);
  const [validating, setValidating] = useState<boolean>(false);
  const [validCode, setValidCode] = useState<boolean>(false);
  const [couponDetails, setCouponDetails] = useState<CouponType | any>();
  const [tokenData, setToken] = useState<string>();
  const [showCouponBox, setShowCouponBox] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>("");
  const [showBillDetails, setShowBillDetails] = useState<boolean>(false);
  const [totalAmount, setTotalAmount] = useState<any>("");
  const [personalInfoCheckbox, setPersonlInfoCheckbox] =
    useState<boolean>(false);
  const [billingAddressCheckbox, setBillingAddressCheckbox] =
    useState<boolean>(false);
  const [defaultCard, setDefaultCard] = useState<boolean>(false);
  const [totalStudentForDiscount, setTotalStudentForDiscount] = useState<any>();
  const [showReferralBox, setShowReferralBox] = useState<boolean>(false);
  const [referral, setReferral] = useState<boolean>(false);
  const [validatingReferral, setValidatingReferral] = useState<boolean>(false);
  const [referralErrorMessage, setReferralErrorMessage] = useState<any>("");
  const [referredBy, setReferredBy] = useState<any>("");
  const [totalChildOfParent, setTotalChildOfParent] = useState<any>();

  const { Option } = Select;

  const toggleCardInput = (value: boolean) => {
    setCardInput(value);
  };

  const currentDate = new Date();
  const promotionStart = new Date("2024-09-16");
  const promotionEnd = new Date("2024-10-12");

  const workshopStart = new Date("2024-10-15")
  const workshopEnd = new Date("2024-10-19")

  const grandOpeningStart = new Date('2024-10-20');
  const grandOpeningEnd = new Date('2025-06-01');

  const winterCampStart = new Date('2024-11-18');
  const winterCampEnd = new Date('2024-12-16');

  const winterCampSecondPhaseStart = new Date('2024-12-16');
  const winterCampSecondPhaseEnd = new Date('2025-01-07');

  const wintercampPercentageOff: any = 15;

  const handlePersonalInfoCheckboxChange = (e: any) => {
    const checked = e.target.checked;
    setPersonlInfoCheckbox(checked);
    if (checked) {
      form.setFieldsValue({
        owner_first_name:
          formValues?.first_name ||
          userDetails?.userData?.UserDetail?.first_name,
        owner_middle_name:
          formValues?.middle_name ||
          userDetails?.userData?.UserDetail?.middle_name,
        owner_last_name:
          formValues?.last_name || userDetails?.userData?.UserDetail?.last_name,
        email: formValues?.email || userDetails?.userData?.email,
      });
    } else {
      form.setFieldsValue({
        owner_first_name: "",
        owner_middle_name: "",
        owner_last_name: "",
        email: "",
      });
    }
  };

  const handleBillingAddressCheckboxChange = (e: any) => {
    const checked = e.target.checked;
    setBillingAddressCheckbox(checked);
    if (checked) {
      form.setFieldsValue({
        address1: formValues?.address1 || userDetails?.address?.address1,
        address2: formValues?.address2 || userDetails?.address?.address2,
        city: formValues?.city || userDetails?.address?.city,
        state: formValues?.state || userDetails?.address?.state,
        zip_code: formValues?.zip_code || userDetails?.address?.zip_code,
      });
    } else {
      form.setFieldsValue({
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip_code: "",
      });
    }
  };

  useEffect(() => {
    if (!newChild) {
      const url = id
        ? `${process.env.REACT_APP_API_BASE_URL
        }/api/payment/cards?id=${parseInt(id)}`
        : `${process.env.REACT_APP_API_BASE_URL}/api/payment/cards`;
      api
        // .get(process.env.REACT_APP_API_BASE_URL + "/api/payment/cards")
        .get(url)
        .then((response: any) => {
          const cardsList: PaymentCardProps[] = [];
          response?.filter((pm: any) => pm?.metadata?.default === "true").
            map((pm: any) => {
              const individualCard: PaymentCardProps = pm.card;
              individualCard.id = pm.id;
              cardsList.push(individualCard);
            });
          setCards(cardsList);
        })
        .catch((error: any) => {
          console.log(error);
        });
    }

    if (parent_id) {
      api
        .get(process.env.REACT_APP_API_BASE_URL + `/api/userdetails/${parent_id}?camp_enroll=${campForm}`)
        .then((data: any) => {
          setTotalChildOfParent(data?.totalChild)
          setShowBillDetails(true);
        })
        .catch((error: any) => {
          message.error("Error fetching child.");
        });
    }
    // fetch plans
    api
      .get(process.env.REACT_APP_API_BASE_URL + "/api/payment/plans")
      .then((data: any[]) => {
        const selPlans = data.filter((plan: any) =>
          selectedPlans.includes(plan.id)
        );
        setPlans(selPlans);
      })
      .catch((error: any) => {
        message.error("Error fetching pricing plans from stripe");
      });
  }, []);

  const handleSubmit = async (values: any) => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet, or the Elements are not yet available.
      return;
    }

    const billingAddress = billingAddressCheckbox
      ? {
        address1: formValues.address1,
        address2: formValues.address2,
        city: formValues.city,
        state: formValues.state,
        zip_code: formValues.zip_code,
      }
      : {
        address1: values.address1,
        address2: values.address2,
        city: values.city,
        state: values.state,
        zip_code: values.zip_code,
      };

    if (cardInput) {
      // Create a PaymentMethod object using the card element.
      const cardElement: any = elements.getElement(CardElement);

      const result = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          name:
            values.owner_first_name +
            " " +
            values.owner_middle_name +
            " " +
            values.owner_last_name,
          email: values.email,
        },
      });

      if (result.error) {
        // Handle payment method creation error.
        message.error(result.error.message);
      } else {
        // PaymentMethod created successfully. Proceed with further processing.
        // now send this pm to backedn for subscribing
        //send true for defaultCard if the no card exist
        let updatedDefaultCard = false;

        if (defaultCard === true) {
          updatedDefaultCard = true;
          setDefaultCard(true);
        } else if (!newChild) {
          updatedDefaultCard = false;
          setDefaultCard(false);
        } else {
          updatedDefaultCard = true;
          setDefaultCard(true);
        }

        onFinish(
          result.paymentMethod.id,
          values.coupons,
          billingAddress,
          userDetails?.enrollment?.branch_location,
          userDetails?.enrollment?.course_id,
          updatedDefaultCard,
          referredBy
        );
      }
    } else {
      //send defaultCard value if the cards exist
      onFinish(
        values.payment_method,
        values.coupons,
        billingAddress,
        userDetails?.enrollment?.branch_location,
        userDetails?.enrollment?.course_id,
        defaultCard,
        referredBy
      );
    }
  };

  // const monthlyFee = plans.length > 0 ? plans[0].amount / 100 : 0;
  const handlePromoChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValidating(true);
    if (e.target.value) {
      api
        .get(
          process.env.REACT_APP_API_BASE_URL +
          "/api/payment/validateCode/" +
          e.target.value
        )
        .then((data: any) => {
          setValidating(false);
          if (!campForm) {
            if (data?.id === "SPRINGKIDS") {
              setValidCode(false)
              setValidating(false);
              setCouponDetails(undefined);
              setErrorMessage({ errors: "Invalid Coupon" });
            } else {
              setValidCode(true);
              setCouponDetails(data);
            }
          } else {
            setValidCode(true);
            setCouponDetails(data);
          }
          calculateTotalAmount(data);
        })
        .catch((error: AxiosError) => {
          setValidating(false);
          setValidCode(false);
          setCouponDetails(undefined);
          setErrorMessage(error?.response?.data);
        });
    } else {
      setValidCode(false);
      setValidating(false);
      setErrorMessage("");
    }
  };

  const handleReferralChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValidatingReferral(true);
    if (e.target.value) {
      api
        .post(
          process.env.REACT_APP_API_BASE_URL +
          "/api/referral/validate-user",
          {
            email: e.target.value
          },
          null
        )
        .then((res: any) => {
          setValidatingReferral(false)
          setReferral(res.success)
          setReferralErrorMessage(res?.message)
          setReferredBy(res?.data)
        })
        .catch((error: any) => {
          setValidatingReferral(false)
          setReferralErrorMessage(error?.response?.data);
        })
    } else {
      setValidating(false);
      setReferralErrorMessage("");
    }
  }

  const handleFormChange = (changedValues: any, allValues: any) => {
    const isValid =
      allValues.owner_first_name &&
      allValues.owner_last_name &&
      allValues.email &&
      allValues.address1 &&
      allValues.city &&
      allValues.state &&
      allValues.zip_code;
    // (!showCouponBox || validCode);

    setIsFormValid(isValid);

    if (allValues.card) {
      if (allValues.card.value.postalCode) {
        setShowBillDetails(true);
        // setTotalAmount(admissionFee + monthlyFee)
      }
    }
  };

  const referralBox = () => {
    setShowReferralBox(!showReferralBox);
    setReferralErrorMessage(!referralErrorMessage);
  }

  const couponBox = () => {
    setShowCouponBox(!showCouponBox);
    setErrorMessage(!errorMessage);
    setCouponDetails(undefined);
    setValidCode(false)
  };

  const calculateTotalAmount: any = async (data?: any) => {
    let amount =
      (currentDate >= grandOpeningStart && currentDate <= grandOpeningEnd
        ? 0
        : admissionFee) + monthlyFee - (monthlyFee * 50 / 100);
    if (data) {
      if (data.amount_off) {
        amount -= data.amount_off / 100;
      } else if (data.percent_off) {
        amount -= (data.percent_off / 100) * monthlyFee;
      }
    }
    setTotalAmount(amount);
  };

  const getTotalEnrolledStudentsForDiscount = () => {
    api
      .get(process.env.REACT_APP_API_BASE_URL + "/api/enrollment/earlybird")
      .then((data: any) => {
        setTotalStudentForDiscount(data[0].total_enrolled);
      });
  };

  useEffect(() => {
    getTotalEnrolledStudentsForDiscount();
  }, []);

  const isInPromotionPeriod =
    currentDate >= promotionStart && currentDate <= promotionEnd;
  const inWorkshopPeriod =
    currentDate >= workshopStart && currentDate <= workshopEnd;
  const isInGrandOpeningPeriod =
    currentDate >= grandOpeningStart && currentDate <= grandOpeningEnd;

  const calculateMonthlyFee = () => {
    if (totalChildOfParent == 1) {
      return (monthlyFee - ((monthlyFee * 75) / 100))
    } else if (totalChildOfParent > 1) {
      return (monthlyFee - ((monthlyFee * 65) / 100))
    } else {
      return (monthlyFee - ((monthlyFee * 50) / 100))
    }
  }

  let discountedMonthlyFee: any;
  discountedMonthlyFee = isInPromotionPeriod
    ? totalStudentForDiscount < 50
      ? (monthlyFee - ((monthlyFee * 50) / 100))
      : (monthlyFee - ((monthlyFee * 30) / 100))
    : inWorkshopPeriod
      ? (monthlyFee - ((monthlyFee * 25) / 100))
      : isInGrandOpeningPeriod
        // ? (monthlyFee - ((monthlyFee * 50) / 100))
        ? calculateMonthlyFee()
        : monthlyFee;

  const totalAmountWithoutDiscount =
    (isInPromotionPeriod || isInGrandOpeningPeriod ? 0 : admissionFee) + discountedMonthlyFee;

  const disablePayButton = () => {
    if (showCouponBox) {
      if (!validCode) {
        return true;
      }
    } else if (showReferralBox) {
      if (!referral) {
        return true;
      }
    } else if (!isFormValid) {
      return true;
    } else {
      return false;
    }
  }

  const calculateCampDiscountWithoutValidCoupon = (numberOfChild: any) => {
    if (currentDate >= winterCampStart && currentDate <= winterCampEnd) {
      if (numberOfChild === 1) {
        const winterCampOff: any = (parseInt(monthlyFee) * 25 / 100);
        const familyDiscount: any = 25 / 100 * (parseInt(monthlyFee) - winterCampOff);
        const totalDiscount: any = winterCampOff + familyDiscount;
        return (totalDiscount).toFixed(2) + " (Winter Camp 25% Off + 25% Family Discount Applied)"
      } else if (numberOfChild > 1) {
        const winterCampOff: any = (parseInt(monthlyFee) * 25 / 100);
        const familyDiscount: any = 15 / 100 * (parseInt(monthlyFee) - winterCampOff);
        const totalDiscount: any = winterCampOff + familyDiscount;
        return (totalDiscount).toFixed(2) + " (Winter Camp 25% Off + 15% Family Discount Applied)"
      } else {
        const totalDiscount: any = parseInt(monthlyFee) * 25 / 100;
        return (totalDiscount).toFixed(2) + " (Winter Camp 25% Off)"
      }
    } else if (currentDate >= winterCampSecondPhaseStart && currentDate <= winterCampSecondPhaseEnd) {
      if (numberOfChild === 1) {
        const winterCampOff: any = (parseInt(monthlyFee) * wintercampPercentageOff / 100);
        const familyDiscount: any = 25 / 100 * (parseInt(monthlyFee) - winterCampOff);
        const totalDiscount: any = winterCampOff + familyDiscount;
        return (totalDiscount).toFixed(2) + ` (Winter Camp ${wintercampPercentageOff}% Off + 25% Family Discount Applied)`
      } else if (numberOfChild > 1) {
        const winterCampOff: any = (parseInt(monthlyFee) * wintercampPercentageOff / 100);
        const familyDiscount: any = 15 / 100 * (parseInt(monthlyFee) - winterCampOff);
        const totalDiscount: any = winterCampOff + familyDiscount;
        return (totalDiscount).toFixed(2) + ` (Winter Camp ${wintercampPercentageOff}% Off + 15% Family Discount Applied)`
      } else {
        const totalDiscount: any = parseInt(monthlyFee) * wintercampPercentageOff / 100;
        return (totalDiscount).toFixed(2) + ` (Winter Camp ${wintercampPercentageOff}% Off)`
      }
    } else {
      if (numberOfChild === 1) {
        const totalDiscount: any = (parseInt(monthlyFee) * 25 / 100);
        return (totalDiscount).toFixed(2) + " (25% Family Discount Applied)"
      } else if (numberOfChild > 1) {
        const totalDiscount: any = (parseInt(monthlyFee) * 15 / 100);
        return (totalDiscount).toFixed(2) + " (15% Family Discount Applied)"
      } else {
        return "0"
      }
    }
  }

  const calculateCampTotalAmountWithoutValidCoupon = (numberOfChild: any) => {
    if (currentDate >= winterCampStart && currentDate <= winterCampEnd) {
      if (numberOfChild === 1) {
        const winterCampOff: any = (parseInt(monthlyFee) * 25 / 100);
        const familyDiscount: any = 25 / 100 * (parseInt(monthlyFee) - winterCampOff);
        const totalDiscount: any = winterCampOff + familyDiscount;
        return (monthlyFee - totalDiscount).toFixed(2)
      } else if (numberOfChild > 1) {
        const winterCampOff: any = (parseInt(monthlyFee) * 25 / 100);
        const familyDiscount: any = 15 / 100 * (parseInt(monthlyFee) - winterCampOff);
        const totalDiscount: any = winterCampOff + familyDiscount;
        return (monthlyFee - totalDiscount).toFixed(2)
      } else {
        const totalDiscount: any = parseInt(monthlyFee) * 25 / 100;
        return (monthlyFee - totalDiscount).toFixed(2)
      }
    } else if (currentDate >= winterCampSecondPhaseStart && currentDate <= winterCampSecondPhaseEnd) {
      if (numberOfChild === 1) {
        const winterCampOff: any = (parseInt(monthlyFee) * wintercampPercentageOff / 100);
        const familyDiscount: any = 25 / 100 * (parseInt(monthlyFee) - winterCampOff);
        const totalDiscount: any = winterCampOff + familyDiscount;
        return (monthlyFee - totalDiscount).toFixed(2)
      } else if (numberOfChild > 1) {
        const winterCampOff: any = (parseInt(monthlyFee) * wintercampPercentageOff / 100);
        const familyDiscount: any = 15 / 100 * (parseInt(monthlyFee) - winterCampOff);
        const totalDiscount: any = winterCampOff + familyDiscount;
        return (monthlyFee - totalDiscount).toFixed(2)
      } else {
        const totalDiscount: any = parseInt(monthlyFee) * wintercampPercentageOff / 100;
        return (monthlyFee - totalDiscount).toFixed(2)
      }
    } else {
      if (numberOfChild === 1) {
        const totalDiscount: any = (parseInt(monthlyFee) * 25 / 100);
        return (monthlyFee - totalDiscount).toFixed(2)
      } else if (numberOfChild > 1) {
        const totalDiscount: any = (parseInt(monthlyFee) * 15 / 100);
        return (monthlyFee - totalDiscount).toFixed(2)
      } else {
        return monthlyFee;
      }
    }
  }

  const calculateCampDiscountWithValidCoupon = (numberOfChild: any) => {
    if (currentDate >= winterCampStart && currentDate <= winterCampEnd) {
      if (numberOfChild === 1) {
        const winterCampOff: any = ((monthlyFee * 25) / 100);
        const familyDiscount: any = (25 / 100 * (parseInt(monthlyFee) - parseInt(monthlyFee) * 25 / 100));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff + familyDiscount)));
        return (winterCampOff + familyDiscount + couponDiscount).toFixed(2) + " (Winter Camp 25% Off + 25% Family Discount + Coupon Discount)"
      } else if (numberOfChild > 1) {
        const winterCampOff: any = ((monthlyFee * 25) / 100);
        const familyDiscount: any = (15 / 100 * (parseInt(monthlyFee) - parseInt(monthlyFee) * 25 / 100));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff + familyDiscount)));
        return (winterCampOff + familyDiscount + couponDiscount).toFixed(2) + " (Winter Camp 25% Off + 15% Family Discount + Coupon Discount)"
      } else {
        const winterCampOff: any = ((monthlyFee * 25) / 100);
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff)));
        return (winterCampOff + couponDiscount).toFixed(2) + " (Winter Camp 25% Off + Coupon Discount)"
      }
    } else if (currentDate >= winterCampSecondPhaseStart && currentDate <= winterCampSecondPhaseEnd) {
      if (numberOfChild === 1) {
        const winterCampOff: any = ((monthlyFee * wintercampPercentageOff) / 100);
        const familyDiscount: any = (25 / 100 * (parseInt(monthlyFee) - parseInt(monthlyFee) * wintercampPercentageOff / 100));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff + familyDiscount)));
        return (winterCampOff + familyDiscount + couponDiscount).toFixed(2) + ` (Winter Camp ${wintercampPercentageOff}% Off + 25% Family Discount + Coupon Discount)`
      } else if (numberOfChild > 1) {
        const winterCampOff: any = ((monthlyFee * wintercampPercentageOff) / 100);
        const familyDiscount: any = (15 / 100 * (parseInt(monthlyFee) - parseInt(monthlyFee) * wintercampPercentageOff / 100));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff + familyDiscount)));
        return (winterCampOff + familyDiscount + couponDiscount).toFixed(2) + ` (Winter Camp ${wintercampPercentageOff}% Off + 15% Family Discount + Coupon Discount)`
      } else {
        const winterCampOff: any = ((monthlyFee * wintercampPercentageOff) / 100);
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff)));
        return (winterCampOff + couponDiscount).toFixed(2) + ` (Winter Camp ${wintercampPercentageOff}% Off + Coupon Discount)`
      }
    } else {
      if (numberOfChild === 1) {
        const familyDiscount: any = (parseInt(monthlyFee) * 25 / 100);
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - familyDiscount));
        return (familyDiscount + couponDiscount).toFixed(2) + " (25% Family Discount Applied + Coupon Discount)"
      } else if (numberOfChild > 1) {
        const familyDiscount: any = (parseInt(monthlyFee) * 15 / 100);
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - familyDiscount));
        return (familyDiscount + couponDiscount).toFixed(2) + " (15% Family Discount Applied + Coupon Discount)"
      } else {
        return (couponDetails?.percent_off / 100) * monthlyFee + " (Coupon Discount)"
      }
    }
  }

  const calculateCampTotalAmountWithValidCoupon = (numberOfChild: any) => {
    if (currentDate >= winterCampStart && currentDate <= winterCampEnd) {
      if (numberOfChild === 1) {
        const winterCampOff: any = ((monthlyFee * 25) / 100);
        const familyDiscount: any = (25 / 100 * (parseInt(monthlyFee) - parseInt(monthlyFee) * 25 / 100));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff + familyDiscount)));
        return (monthlyFee - (winterCampOff + familyDiscount + couponDiscount)).toFixed(2)
      } else if (numberOfChild > 1) {
        const winterCampOff: any = ((monthlyFee * 25) / 100);
        const familyDiscount: any = (15 / 100 * (parseInt(monthlyFee) - parseInt(monthlyFee) * 25 / 100));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff + familyDiscount)));
        return (monthlyFee - (winterCampOff + familyDiscount + couponDiscount)).toFixed(2)
      } else {
        const winterCampOff: any = ((monthlyFee * 25) / 100);
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff)));
        return (monthlyFee - (winterCampOff + couponDiscount)).toFixed(2)
      }
    } else if (currentDate >= winterCampSecondPhaseStart && currentDate <= winterCampSecondPhaseEnd) {
      if (numberOfChild === 1) {
        const winterCampOff: any = ((monthlyFee * wintercampPercentageOff) / 100);
        const familyDiscount: any = (25 / 100 * (parseInt(monthlyFee) - parseInt(monthlyFee) * wintercampPercentageOff / 100));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff + familyDiscount)));
        return (monthlyFee - (winterCampOff + familyDiscount + couponDiscount)).toFixed(2)
      } else if (numberOfChild > 1) {
        const winterCampOff: any = ((monthlyFee * wintercampPercentageOff) / 100);
        const familyDiscount: any = (15 / 100 * (parseInt(monthlyFee) - parseInt(monthlyFee) * wintercampPercentageOff / 100));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff + familyDiscount)));
        return (monthlyFee - (winterCampOff + familyDiscount + couponDiscount)).toFixed(2)
      } else {
        const winterCampOff: any = ((monthlyFee * wintercampPercentageOff) / 100);
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff)));
        return (monthlyFee - (winterCampOff + couponDiscount)).toFixed(2)
      }
    } else {
      if (numberOfChild === 1) {
        const familyDiscount: any = (parseInt(monthlyFee) * 25 / 100);
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - familyDiscount));
        return (monthlyFee - (familyDiscount + couponDiscount)).toFixed(2)
      } else if (numberOfChild > 1) {
        const familyDiscount: any = (parseInt(monthlyFee) * 15 / 100);
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - familyDiscount));
        return (monthlyFee - (familyDiscount + couponDiscount)).toFixed(2)
      } else {
        return (monthlyFee - ((couponDetails?.percent_off / 100) * monthlyFee)).toFixed(2)
      }
    }
  }

  const calculateCampDiscountWithValidCouponAmountOff = (numberOfChild: any) => {
    if (currentDate >= winterCampStart && currentDate <= winterCampEnd) {
      if (numberOfChild === 1) {
        const winterCampOff: any = ((monthlyFee * 25) / 100);
        const familyDiscount: any = (25 / 100 * (parseInt(monthlyFee) - parseInt(monthlyFee) * 25 / 100));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff + familyDiscount)));
        return (winterCampOff + familyDiscount + couponDiscount).toFixed(2) + " (Winter Camp 25% Off + 25% Family Discount + Coupon Discount)"
      } else if (numberOfChild > 1) {
        const winterCampOff: any = ((monthlyFee * 25) / 100);
        const familyDiscount: any = (15 / 100 * (parseInt(monthlyFee) - parseInt(monthlyFee) * 25 / 100));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff + familyDiscount)));
        return (winterCampOff + familyDiscount + couponDiscount).toFixed(2) + " (Winter Camp 25% Off + 15% Family Discount + Coupon Discount)"
      } else {
        const winterCampOff: any = ((monthlyFee * 25) / 100);
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff)));
        return (winterCampOff + couponDiscount).toFixed(2) + " (Winter Camp 25% Off + Coupon Discount)"
      }
    } else if (currentDate >= winterCampSecondPhaseStart && currentDate <= winterCampSecondPhaseEnd) {
      if (numberOfChild === 1) {
        const winterCampOff: any = ((monthlyFee * wintercampPercentageOff) / 100);
        const familyDiscount: any = (25 / 100 * (parseInt(monthlyFee) - parseInt(monthlyFee) * wintercampPercentageOff / 100));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff + familyDiscount)));
        return (winterCampOff + familyDiscount + couponDiscount).toFixed(2) + ` (Winter Camp ${wintercampPercentageOff}% Off + 25% Family Discount + Coupon Discount)`
      } else if (numberOfChild > 1) {
        const winterCampOff: any = ((monthlyFee * wintercampPercentageOff) / 100);
        const familyDiscount: any = (15 / 100 * (parseInt(monthlyFee) - parseInt(monthlyFee) * wintercampPercentageOff / 100));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff + familyDiscount)));
        return (winterCampOff + familyDiscount + couponDiscount).toFixed(2) + ` (Winter Camp ${wintercampPercentageOff}% Off + 15% Family Discount + Coupon Discount)`
      } else {
        const winterCampOff: any = ((monthlyFee * wintercampPercentageOff) / 100);
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff)));
        return (winterCampOff + couponDiscount).toFixed(2) + ` (Winter Camp ${wintercampPercentageOff}% Off + Coupon Discount)`
      }
    } else {
      if (numberOfChild === 1) {
        const familyDiscount: any = (parseInt(monthlyFee) * 25 / 100);
        const couponDiscount: any = (couponDetails?.amount_off / 100);
        return (familyDiscount + couponDiscount).toFixed(2) + " (25% Family Discount Applied + Coupon Discount)"
      } else if (numberOfChild > 1) {
        const familyDiscount: any = (parseInt(monthlyFee) * 15 / 100);
        const couponDiscount: any = (couponDetails?.amount_off / 100);
        return (familyDiscount + couponDiscount).toFixed(2) + " (15% Family Discount Applied + Coupon Discount)"
      } else {
        return (couponDetails?.amount_off / 100) + " (Coupon Discount)"
      }
    }
  }

  const calculateCampTotalAmountWithValidCouponAmountOff = (numberOfChild: any) => {
    if (currentDate >= winterCampStart && currentDate <= winterCampEnd) {
      if (numberOfChild === 1) {
        const winterCampOff: any = ((monthlyFee * 25) / 100);
        const familyDiscount: any = (25 / 100 * (parseInt(monthlyFee) - parseInt(monthlyFee) * 25 / 100));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff + familyDiscount)));
        return (monthlyFee - (winterCampOff + familyDiscount + couponDiscount)).toFixed(2)
      } else if (numberOfChild > 1) {
        const winterCampOff: any = ((monthlyFee * 25) / 100);
        const familyDiscount: any = (15 / 100 * (parseInt(monthlyFee) - parseInt(monthlyFee) * 25 / 100));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff + familyDiscount)));
        return (monthlyFee - (winterCampOff + familyDiscount + couponDiscount)).toFixed(2)
      } else {
        const winterCampOff: any = ((monthlyFee * 25) / 100);
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff)));
        return (monthlyFee - (winterCampOff + couponDiscount)).toFixed(2)
      }
    } else if (currentDate >= winterCampSecondPhaseStart && currentDate <= winterCampSecondPhaseEnd) {
      if (numberOfChild === 1) {
        const winterCampOff: any = ((monthlyFee * wintercampPercentageOff) / 100);
        const familyDiscount: any = (25 / 100 * (parseInt(monthlyFee) - parseInt(monthlyFee) * wintercampPercentageOff / 100));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff + familyDiscount)));
        return (monthlyFee - (winterCampOff + familyDiscount + couponDiscount)).toFixed(2)
      } else if (numberOfChild > 1) {
        const winterCampOff: any = ((monthlyFee * wintercampPercentageOff) / 100);
        const familyDiscount: any = (15 / 100 * (parseInt(monthlyFee) - parseInt(monthlyFee) * wintercampPercentageOff / 100));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff + familyDiscount)));
        return (monthlyFee - (winterCampOff + familyDiscount + couponDiscount)).toFixed(2)
      } else {
        const winterCampOff: any = ((monthlyFee * wintercampPercentageOff) / 100);
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) - (winterCampOff)));
        return (monthlyFee - (winterCampOff + couponDiscount)).toFixed(2)
      }
    } else {
      if (numberOfChild === 1) {
        const familyDiscount: any = (parseInt(monthlyFee) * 25 / 100);
        const couponDiscount: any = (couponDetails?.amount_off / 100);
        return (monthlyFee - (familyDiscount + couponDiscount)).toFixed(2)
      } else if (numberOfChild > 1) {
        const familyDiscount: any = (parseInt(monthlyFee) * 15 / 100);
        const couponDiscount: any = (couponDetails?.amount_off / 100);
        return (monthlyFee - (familyDiscount + couponDiscount)).toFixed(2)
      } else {
        return (parseInt(monthlyFee) - ((couponDetails?.amount_off / 100)))
      }
    }
  }

  const calculateDailyCampDiscountWithoutValidCoupon = (numberOfChild: any) => {
    if (currentDate >= winterCampStart && currentDate <= winterCampEnd) {
      if (numberOfChild === 1) {
        const winterCampOff: any = (parseInt(monthlyFee) * selectedDates * 25 / 100);
        const familyDiscount: any = 25 / 100 * (parseInt(monthlyFee) * selectedDates - winterCampOff);
        const totalDiscount: any = winterCampOff + familyDiscount;
        return (totalDiscount).toFixed(2) + " (Winter Camp 25% Off + 25% Family Discount Applied)"
      } else if (numberOfChild > 1) {
        const winterCampOff: any = (parseInt(monthlyFee) * selectedDates * 25 / 100);
        const familyDiscount: any = 15 / 100 * (parseInt(monthlyFee) * selectedDates - winterCampOff);
        const totalDiscount: any = winterCampOff + familyDiscount;
        return (totalDiscount).toFixed(2) + " (Winter Camp 25% Off + 15% Family Discount Applied)"
      } else {
        const totalDiscount: any = parseInt(monthlyFee) * selectedDates * 25 / 100;
        return (totalDiscount).toFixed(2) + " (Winter Camp 25% Off)"
      }
    } else if (currentDate >= winterCampSecondPhaseStart && currentDate <= winterCampSecondPhaseEnd) {
      if (numberOfChild === 1) {
        const winterCampOff: any = (parseInt(monthlyFee) * selectedDates * wintercampPercentageOff / 100);
        const familyDiscount: any = 25 / 100 * (parseInt(monthlyFee) * selectedDates - winterCampOff);
        const totalDiscount: any = winterCampOff + familyDiscount;
        return (totalDiscount).toFixed(2) + ` (Winter Camp ${wintercampPercentageOff}% Off + 25% Family Discount Applied)`
      } else if (numberOfChild > 1) {
        const winterCampOff: any = (parseInt(monthlyFee) * selectedDates * wintercampPercentageOff / 100);
        const familyDiscount: any = 15 / 100 * (parseInt(monthlyFee) * selectedDates - winterCampOff);
        const totalDiscount: any = winterCampOff + familyDiscount;
        return (totalDiscount).toFixed(2) + ` (Winter Camp ${wintercampPercentageOff}% Off + 15% Family Discount Applied)`
      } else {
        const totalDiscount: any = parseInt(monthlyFee) * selectedDates * wintercampPercentageOff / 100;
        return (totalDiscount).toFixed(2) + ` (Winter Camp ${wintercampPercentageOff}% Off)`
      }
    } else {
      if (numberOfChild === 1) {
        const totalDiscount: any = (parseInt(monthlyFee) * selectedDates * 25 / 100);
        return (totalDiscount).toFixed(2) + " (25% Family Discount Applied)"
      } else if (numberOfChild > 1) {
        const totalDiscount: any = (parseInt(monthlyFee) * selectedDates * 15 / 100);
        return (totalDiscount).toFixed(2) + " (15% Family Discount Applied)"
      } else {
        return "0 (No Discount Applied)"
      }
    }
  }

  const calculateDailyCampTotalAmountWithoutValidCoupon = (numberOfChild: any) => {
    if (currentDate >= winterCampStart && currentDate <= winterCampEnd) {
      if (numberOfChild === 1) {
        const winterCampOff: any = (parseInt(monthlyFee) * selectedDates * 25 / 100);
        const familyDiscount: any = 25 / 100 * ((parseInt(monthlyFee) * selectedDates) - winterCampOff);
        const totalDiscount: any = winterCampOff + familyDiscount;
        return ((monthlyFee * selectedDates) - totalDiscount).toFixed(2)
      } else if (numberOfChild > 1) {
        const winterCampOff: any = (parseInt(monthlyFee) * selectedDates * 25 / 100);
        const familyDiscount: any = 15 / 100 * ((parseInt(monthlyFee) * selectedDates) - winterCampOff);
        const totalDiscount: any = winterCampOff + familyDiscount;
        return ((monthlyFee * selectedDates) - totalDiscount).toFixed(2)
      } else {
        const totalDiscount: any = parseInt(monthlyFee) * selectedDates * 25 / 100;
        return ((monthlyFee * selectedDates) - totalDiscount).toFixed(2)
      }
    } else if (currentDate >= winterCampSecondPhaseStart && currentDate <= winterCampSecondPhaseEnd) {
      if (numberOfChild === 1) {
        const winterCampOff: any = (parseInt(monthlyFee) * selectedDates * wintercampPercentageOff / 100);
        const familyDiscount: any = 25 / 100 * ((parseInt(monthlyFee) * selectedDates) - winterCampOff);
        const totalDiscount: any = winterCampOff + familyDiscount;
        return ((monthlyFee * selectedDates) - totalDiscount).toFixed(2)
      } else if (numberOfChild > 1) {
        const winterCampOff: any = (parseInt(monthlyFee) * selectedDates * wintercampPercentageOff / 100);
        const familyDiscount: any = 15 / 100 * ((parseInt(monthlyFee) * selectedDates) - winterCampOff);
        const totalDiscount: any = winterCampOff + familyDiscount;
        return ((monthlyFee * selectedDates) - totalDiscount).toFixed(2)
      } else {
        const totalDiscount: any = parseInt(monthlyFee) * selectedDates * wintercampPercentageOff / 100;
        return ((monthlyFee * selectedDates) - totalDiscount).toFixed(2)
      }
    } else {
      if (numberOfChild === 1) {
        const totalDiscount: any = (parseInt(monthlyFee) * selectedDates * 25 / 100);
        return ((monthlyFee * selectedDates) - totalDiscount).toFixed(2)
      } else if (numberOfChild > 1) {
        const totalDiscount: any = (parseInt(monthlyFee) * selectedDates * 15 / 100);
        return ((monthlyFee * selectedDates) - totalDiscount).toFixed(2)
      } else {
        return (monthlyFee * selectedDates)
      }
    }
  }

  const calculateDailyCampDiscountWithValidCoupon = (numberOfChild: any) => {
    if (currentDate >= winterCampStart && currentDate <= winterCampEnd) {
      if (numberOfChild === 1) {
        const winterCampOff: any = ((monthlyFee * 25) * selectedDates / 100);
        const familyDiscount: any = (25 / 100 * ((parseInt(monthlyFee) * selectedDates) - (parseInt(monthlyFee) * selectedDates * 25 / 100)));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) * selectedDates - (winterCampOff + familyDiscount)));
        return (winterCampOff + familyDiscount + couponDiscount).toFixed(2) + " (Winter Camp 25% Off + 25% Family Discount + Coupon Discount)"
      } else if (numberOfChild > 1) {
        const winterCampOff: any = ((monthlyFee * 25) * selectedDates / 100);
        const familyDiscount: any = (15 / 100 * (parseInt(monthlyFee) * selectedDates - (parseInt(monthlyFee) * selectedDates * 25 / 100)));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * ((parseInt(monthlyFee) * selectedDates) - (winterCampOff + familyDiscount)));
        return (winterCampOff + familyDiscount + couponDiscount).toFixed(2) + " (Winter Camp 25% Off + 15% Family Discount + Coupon Discount)"
      } else {
        const winterCampOff: any = ((monthlyFee * 25) * selectedDates / 100);
        const couponDiscount: any = (couponDetails?.percent_off / 100 * ((parseInt(monthlyFee) * selectedDates) - (winterCampOff)));
        return (winterCampOff + couponDiscount).toFixed(2) + " (Winter Camp 25% Off + Coupon Discount)"
      }
    } else if (currentDate >= winterCampSecondPhaseStart && currentDate <= winterCampSecondPhaseEnd) {
      if (numberOfChild === 1) {
        const winterCampOff: any = ((monthlyFee * wintercampPercentageOff) * selectedDates / 100);
        const familyDiscount: any = (25 / 100 * ((parseInt(monthlyFee) * selectedDates) - (parseInt(monthlyFee) * selectedDates * wintercampPercentageOff / 100)));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) * selectedDates - (winterCampOff + familyDiscount)));
        return (winterCampOff + familyDiscount + couponDiscount).toFixed(2) + ` (Winter Camp ${wintercampPercentageOff}% Off + 25% Family Discount + Coupon Discount)`
      } else if (numberOfChild > 1) {
        const winterCampOff: any = ((monthlyFee * wintercampPercentageOff) * selectedDates / 100);
        const familyDiscount: any = (15 / 100 * (parseInt(monthlyFee) * selectedDates - (parseInt(monthlyFee) * selectedDates * wintercampPercentageOff / 100)));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * ((parseInt(monthlyFee) * selectedDates) - (winterCampOff + familyDiscount)));
        return (winterCampOff + familyDiscount + couponDiscount).toFixed(2) + ` (Winter Camp ${wintercampPercentageOff}% Off + 15% Family Discount + Coupon Discount)`
      } else {
        const winterCampOff: any = ((monthlyFee * wintercampPercentageOff) * selectedDates / 100);
        const couponDiscount: any = (couponDetails?.percent_off / 100 * ((parseInt(monthlyFee) * selectedDates) - (winterCampOff)));
        return (winterCampOff + couponDiscount).toFixed(2) + ` (Winter Camp ${wintercampPercentageOff}% Off + Coupon Discount)`
      }
    } else {
      if (numberOfChild === 1) {
        const familyDiscount: any = (parseInt(monthlyFee) * selectedDates * 25 / 100);
        const couponDiscount: any = (couponDetails?.percent_off / 100 * ((parseInt(monthlyFee) * selectedDates) - familyDiscount));
        return (familyDiscount + couponDiscount).toFixed(2) + " (25% Family Discount Applied + Coupon Discount)"
      } else if (numberOfChild > 1) {
        const familyDiscount: any = (parseInt(monthlyFee) * selectedDates * 15 / 100);
        const couponDiscount: any = (couponDetails?.percent_off / 100 * ((parseInt(monthlyFee) * selectedDates) - familyDiscount));
        return (familyDiscount + couponDiscount).toFixed(2) + " (15% Family Discount Applied + Coupon Discount)"
      } else {
        return (couponDetails?.percent_off / 100) * monthlyFee * selectedDates + " (Coupon Discount)"
      }
    }
  }

  const calculateDailyCampTotalAmountWithValidCoupon = (numberOfChild: any) => {
    if (currentDate >= winterCampStart && currentDate <= winterCampEnd) {
      if (numberOfChild === 1) {
        const winterCampOff: any = ((monthlyFee * 25) * selectedDates / 100);
        const familyDiscount: any = (25 / 100 * ((parseInt(monthlyFee) * selectedDates) - (parseInt(monthlyFee) * selectedDates * 25 / 100)));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) * selectedDates - (winterCampOff + familyDiscount)));
        return ((monthlyFee * selectedDates) - (winterCampOff + familyDiscount + couponDiscount)).toFixed(2)
      } else if (numberOfChild > 1) {
        const winterCampOff: any = ((monthlyFee * 25) * selectedDates / 100);
        const familyDiscount: any = (15 / 100 * (parseInt(monthlyFee) * selectedDates - parseInt(monthlyFee) * selectedDates * 25 / 100));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) * selectedDates - (winterCampOff + familyDiscount)));
        return ((monthlyFee * selectedDates) - (winterCampOff + familyDiscount + couponDiscount)).toFixed(2)
      } else {
        const winterCampOff: any = ((monthlyFee * 25) * selectedDates / 100);
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) * selectedDates - (winterCampOff)));
        return ((monthlyFee * selectedDates) - (winterCampOff + couponDiscount)).toFixed(2)
      }
    } else if (currentDate >= winterCampSecondPhaseStart && currentDate <= winterCampSecondPhaseEnd) {
      if (numberOfChild === 1) {
        const winterCampOff: any = ((monthlyFee * wintercampPercentageOff) * selectedDates / 100);
        const familyDiscount: any = (25 / 100 * ((parseInt(monthlyFee) * selectedDates) - (parseInt(monthlyFee) * selectedDates * wintercampPercentageOff / 100)));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) * selectedDates - (winterCampOff + familyDiscount)));
        return ((monthlyFee * selectedDates) - (winterCampOff + familyDiscount + couponDiscount)).toFixed(2)
      } else if (numberOfChild > 1) {
        const winterCampOff: any = ((monthlyFee * wintercampPercentageOff) * selectedDates / 100);
        const familyDiscount: any = (15 / 100 * (parseInt(monthlyFee) * selectedDates - parseInt(monthlyFee) * selectedDates * wintercampPercentageOff / 100));
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) * selectedDates - (winterCampOff + familyDiscount)));
        return ((monthlyFee * selectedDates) - (winterCampOff + familyDiscount + couponDiscount)).toFixed(2)
      } else {
        const winterCampOff: any = ((monthlyFee * wintercampPercentageOff) * selectedDates / 100);
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) * selectedDates - (winterCampOff)));
        return ((monthlyFee * selectedDates) - (winterCampOff + couponDiscount)).toFixed(2)
      }
    } else {
      if (numberOfChild === 1) {
        const familyDiscount: any = (parseInt(monthlyFee) * selectedDates * 25 / 100);
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) * selectedDates - familyDiscount));
        return ((monthlyFee * selectedDates) - (familyDiscount + couponDiscount)).toFixed(2)
      } else if (numberOfChild > 1) {
        const familyDiscount: any = (parseInt(monthlyFee) * selectedDates * 15 / 100);
        const couponDiscount: any = (couponDetails?.percent_off / 100 * (parseInt(monthlyFee) * selectedDates - familyDiscount));
        return ((monthlyFee * selectedDates) - (familyDiscount + couponDiscount)).toFixed(2)
      } else {
        return ((monthlyFee * selectedDates) - (couponDetails?.percent_off / 100) * (monthlyFee * selectedDates)).toFixed(2)
      }
    }
  }

  const grandOpeningDiscountPercent: number = 10;
  let familyDiscountPercent: number = 0;

  if (totalChildOfParent === 1) {
    familyDiscountPercent = 25
  } else {
    familyDiscountPercent = 15
  }

  const promotion_name = "Spring Special Discount Applied";

  const monthlyFeeDiscount = (totalChildOfParent: any) => {
    if ((!validCode || !showCouponBox) && currentDate >= grandOpeningStart && currentDate <= grandOpeningEnd) {
      if (totalChildOfParent === 1) {
        const grandOpeningDiscount = (monthlyFee * grandOpeningDiscountPercent) / 100;
        const familyDiscount = (familyDiscountPercent / 100) * (parseInt(monthlyFee) - grandOpeningDiscount)
        return ((grandOpeningDiscount) + familyDiscount) + ` (${grandOpeningDiscountPercent}% ${promotion_name} + ${familyDiscountPercent}% Family Discount Applied)`;
      } else if (totalChildOfParent > 1) {
        const grandOpeningDiscount = (monthlyFee * grandOpeningDiscountPercent) / 100;
        const familyDiscount = (familyDiscountPercent / 100) * (parseInt(monthlyFee) - grandOpeningDiscount)
        return (grandOpeningDiscount + familyDiscount).toFixed(2) + ` (${grandOpeningDiscountPercent}% ${promotion_name} + ${familyDiscountPercent}% Family Discount Applied)`;
      } else {
        return (((parseInt(monthlyFee) * grandOpeningDiscountPercent) / 100)) + ` (${grandOpeningDiscountPercent}% ${promotion_name})`;
      }
    } else if (validCode && couponDetails?.percent_off) {
      if (currentDate >= grandOpeningStart &&
        currentDate <= grandOpeningEnd) {
        if (totalChildOfParent >= 1) {
          const grandOpeningDiscount = (parseInt(monthlyFee) * grandOpeningDiscountPercent) / 100;
          const couponDiscount = (couponDetails?.percent_off / 100) * (parseInt(monthlyFee) - grandOpeningDiscount)
          const familyDiscount = (familyDiscountPercent / 100) * (monthlyFee - (grandOpeningDiscount + couponDiscount))
          return (grandOpeningDiscount + couponDiscount + familyDiscount).toFixed(2) + ` (${grandOpeningDiscountPercent}% ${promotion_name} + ${familyDiscountPercent}% Family Discount + Coupon Discount)`
        } else {
          const grandOpeningDiscount = (parseInt(monthlyFee) * grandOpeningDiscountPercent) / 100;
          const couponDiscount = (couponDetails?.percent_off / 100) * (parseInt(monthlyFee) - grandOpeningDiscount)
          return (grandOpeningDiscount + couponDiscount).toFixed(2) + ` (${grandOpeningDiscountPercent}% ${promotion_name} + Coupon Discount)`
        }
      } else {
        const couponDiscount = (couponDetails?.percent_off / 100) * (monthlyFee)
        return (couponDiscount).toFixed(2) + " (Coupon Discount)"
      }
    } else if (validCode && couponDetails?.amount_off) {
      if (currentDate >= grandOpeningStart &&
        currentDate <= grandOpeningEnd) {
        if (totalChildOfParent >= 1) {
          const grandOpeningDiscount = (monthlyFee * grandOpeningDiscountPercent) / 100;
          const couponDiscount = (parseInt(couponDetails?.amount_off) / 100)
          const familyDiscount = (familyDiscountPercent / 100) * (parseInt(monthlyFee) - (grandOpeningDiscount))
          return (grandOpeningDiscount + couponDiscount + familyDiscount).toFixed(2) + ` (${grandOpeningDiscountPercent}% ${promotion_name} + ${familyDiscountPercent}% Family Discount + Coupon Discount)`
        } else {
          const grandOpeningDiscount = (monthlyFee * grandOpeningDiscountPercent) / 100;
          const couponDiscount = (parseInt(couponDetails?.amount_off) / 100);
          return (grandOpeningDiscount + couponDiscount).toFixed(2) + ` (${grandOpeningDiscountPercent}% ${promotion_name} + Coupon Discount)`
        }
      } else {
        const couponDiscount = parseInt(monthlyFee) - (parseInt(couponDetails?.amount_off) / 100)
        return (couponDiscount).toFixed(2) + " (Coupon Discount)"
      }
    } else if (validCode && currentDate >= grandOpeningStart && currentDate <= grandOpeningEnd) {
      if (totalChildOfParent === 1) {
        const grandOpeningDiscount = (monthlyFee * grandOpeningDiscountPercent) / 100;
        const familyDiscount = (familyDiscountPercent / 100) * (parseInt(monthlyFee) - grandOpeningDiscount)
        return ((grandOpeningDiscount) + familyDiscount).toFixed(2) + ` (${grandOpeningDiscountPercent}% ${promotion_name} + ${familyDiscountPercent}% Family Discount Applied)`;
      } else if (totalChildOfParent > 1) {
        const grandOpeningDiscount = (monthlyFee * grandOpeningDiscountPercent) / 100;
        const familyDiscount = (familyDiscountPercent / 100) * (parseInt(monthlyFee) - grandOpeningDiscount)
        return (grandOpeningDiscount + familyDiscount).toFixed(2) + ` (${grandOpeningDiscountPercent}% ${promotion_name} + ${familyDiscountPercent}% Family Discount Applied)`;
      } else {
        return (((parseInt(monthlyFee) * grandOpeningDiscountPercent) / 100)) + ` (${grandOpeningDiscountPercent}% ${promotion_name})`;
      }
    }
    else {
      return "0";
    }
  }

  const onlineAndSpecializedCourseDiscount = (totalChildOfParent: any) => {
    const courseFeeFloat = parseFloat(courseFee);
    const roundToTwoDecimals = (value: number) => Math.round(value * 100) / 100;
  
    if ((!validCode || !showCouponBox) && currentDate >= grandOpeningStart && currentDate <= grandOpeningEnd) {
      if (totalChildOfParent === 1) {
        const grandOpeningDiscount = roundToTwoDecimals((courseFeeFloat * grandOpeningDiscountPercent) / 100);
        const familyDiscount = roundToTwoDecimals((familyDiscountPercent / 100) * (courseFeeFloat - grandOpeningDiscount));
        const totalDiscount = roundToTwoDecimals(grandOpeningDiscount + familyDiscount);
        return `${totalDiscount} (${grandOpeningDiscountPercent}% ${promotion_name} + ${familyDiscountPercent}% Family Discount Applied)`;
      } else if (totalChildOfParent > 1) {
        const grandOpeningDiscount = roundToTwoDecimals((courseFeeFloat * grandOpeningDiscountPercent) / 100);
        const familyDiscount = roundToTwoDecimals((familyDiscountPercent / 100) * (courseFeeFloat - grandOpeningDiscount));
        const totalDiscount = roundToTwoDecimals(grandOpeningDiscount + familyDiscount);
        return `${totalDiscount} (${grandOpeningDiscountPercent}% ${promotion_name} + ${familyDiscountPercent}% Family Discount Applied)`;
      } else {
        const grandOpeningDiscount = roundToTwoDecimals((courseFeeFloat * grandOpeningDiscountPercent) / 100);
        return `${grandOpeningDiscount} (${grandOpeningDiscountPercent}% ${promotion_name})`;
      }
    } else if (validCode && couponDetails?.percent_off) {
      if (currentDate >= grandOpeningStart && currentDate <= grandOpeningEnd) {
        if (totalChildOfParent >= 1) {
          const grandOpeningDiscount = roundToTwoDecimals((courseFeeFloat * grandOpeningDiscountPercent) / 100);
          const couponDiscount = roundToTwoDecimals((couponDetails?.percent_off / 100) * (courseFeeFloat - grandOpeningDiscount));
          const familyDiscount = roundToTwoDecimals((familyDiscountPercent / 100) * (courseFeeFloat - (grandOpeningDiscount + couponDiscount)));
          const totalDiscount = roundToTwoDecimals(grandOpeningDiscount + couponDiscount + familyDiscount);
          return `${totalDiscount} (${grandOpeningDiscountPercent}% ${promotion_name} + ${familyDiscountPercent}% Family Discount + Coupon Discount)`;
        } else {
          const grandOpeningDiscount = roundToTwoDecimals((courseFeeFloat * grandOpeningDiscountPercent) / 100);
          const couponDiscount = roundToTwoDecimals((couponDetails?.percent_off / 100) * (courseFeeFloat - grandOpeningDiscount));
          const totalDiscount = roundToTwoDecimals(grandOpeningDiscount + couponDiscount);
          return `${totalDiscount} (${grandOpeningDiscountPercent}% ${promotion_name} + Coupon Discount)`;
        }
      } else {
        const couponDiscount = roundToTwoDecimals((couponDetails?.percent_off / 100) * courseFeeFloat);
        return `${couponDiscount} (Coupon Discount)`;
      }
    } else if (validCode && couponDetails?.amount_off) {
      if (currentDate >= grandOpeningStart && currentDate <= grandOpeningEnd) {
        if (totalChildOfParent >= 1) {
          const grandOpeningDiscount = roundToTwoDecimals((courseFeeFloat * grandOpeningDiscountPercent) / 100);
          const couponDiscount = roundToTwoDecimals((parseInt(couponDetails?.amount_off) / 100));
          const familyDiscount = roundToTwoDecimals((familyDiscountPercent / 100) * (courseFeeFloat - grandOpeningDiscount));
          const totalDiscount = roundToTwoDecimals(grandOpeningDiscount + couponDiscount + familyDiscount);
          return `${totalDiscount} (${grandOpeningDiscountPercent}% ${promotion_name} + ${familyDiscountPercent}% Family Discount + Coupon Discount)`;
        } else {
          const grandOpeningDiscount = roundToTwoDecimals((courseFeeFloat * grandOpeningDiscountPercent) / 100);
          const couponDiscount = roundToTwoDecimals((parseInt(couponDetails?.amount_off) / 100));
          const totalDiscount = roundToTwoDecimals(grandOpeningDiscount + couponDiscount);
          return `${totalDiscount} (${grandOpeningDiscountPercent}% ${promotion_name} + Coupon Discount)`;
        }
      } else {
        const couponDiscount = roundToTwoDecimals(courseFeeFloat - (parseInt(couponDetails?.amount_off) / 100));
        return `${couponDiscount} (Coupon Discount)`;
      }
    } else if (validCode && currentDate >= grandOpeningStart && currentDate <= grandOpeningEnd) {
      if (totalChildOfParent === 1) {
        const grandOpeningDiscount = roundToTwoDecimals((courseFeeFloat * grandOpeningDiscountPercent) / 100);
        const familyDiscount = roundToTwoDecimals((familyDiscountPercent / 100) * (courseFeeFloat - grandOpeningDiscount));
        const totalDiscount = roundToTwoDecimals(grandOpeningDiscount + familyDiscount);
        return `${totalDiscount} (${grandOpeningDiscountPercent}% ${promotion_name} + ${familyDiscountPercent}% Family Discount Applied)`;
      } else if (totalChildOfParent > 1) {
        const grandOpeningDiscount = roundToTwoDecimals((courseFeeFloat * grandOpeningDiscountPercent) / 100);
        const familyDiscount = roundToTwoDecimals((familyDiscountPercent / 100) * (courseFeeFloat - grandOpeningDiscount));
        const totalDiscount = roundToTwoDecimals(grandOpeningDiscount + familyDiscount);
        return `${totalDiscount} (${grandOpeningDiscountPercent}% ${promotion_name} + ${familyDiscountPercent}% Family Discount Applied)`;
      } else {
        const grandOpeningDiscount = roundToTwoDecimals((courseFeeFloat * grandOpeningDiscountPercent) / 100);
        return `${grandOpeningDiscount} (${grandOpeningDiscountPercent}% ${promotion_name})`;
      }
    } else {
      return "0";
    }
  };

  const monthlyFeeTotalAmount = (totalChildOfParent: any) => {
    if ((!validCode || !showCouponBox) && currentDate >= grandOpeningStart && currentDate <= grandOpeningEnd) {
      if (totalChildOfParent === 1) {
        const grandOpeningDiscount = (monthlyFee * grandOpeningDiscountPercent) / 100;
        const familyDiscount = (familyDiscountPercent / 100) * (parseInt(monthlyFee) - grandOpeningDiscount)
        return ((parseInt(monthlyFee)) - (grandOpeningDiscount + familyDiscount));
      } else if (totalChildOfParent > 1) {
        const grandOpeningDiscount = (monthlyFee * grandOpeningDiscountPercent) / 100;
        const familyDiscount = (familyDiscountPercent / 100) * (monthlyFee - grandOpeningDiscount)
        return ((parseInt(monthlyFee)) - (grandOpeningDiscount + familyDiscount));
      } else {
        return ((parseInt(monthlyFee)) - ((monthlyFee * grandOpeningDiscountPercent) / 100));
      }
    } else if (validCode && couponDetails?.percent_off) {
      if (currentDate >= grandOpeningStart &&
        currentDate <= grandOpeningEnd) {
        if (totalChildOfParent >= 1) {
          const grandOpeningDiscount = (monthlyFee * grandOpeningDiscountPercent) / 100;
          const couponDiscount = (couponDetails?.percent_off / 100) * (monthlyFee - grandOpeningDiscount)
          const familyDiscount = (familyDiscountPercent / 100) * (monthlyFee - (grandOpeningDiscount + couponDiscount))
          return ((parseInt(monthlyFee)) - (grandOpeningDiscount + couponDiscount + familyDiscount)).toFixed(2)
        } else {
          const grandOpeningDiscount = (monthlyFee * grandOpeningDiscountPercent) / 100;
          const couponDiscount = (couponDetails?.percent_off / 100) * (monthlyFee - grandOpeningDiscount)
          return ((parseInt(monthlyFee)) - (grandOpeningDiscount + couponDiscount)).toFixed(2)
        }
      } else {
        const couponDiscount = (couponDetails?.percent_off / 100) * (monthlyFee)
        return ((parseInt(monthlyFee)) - couponDiscount).toFixed(2)
      }
    } else if (validCode && couponDetails?.amount_off) {
      if (currentDate >= grandOpeningStart &&
        currentDate <= grandOpeningEnd) {
        if (totalChildOfParent >= 1) {
          const grandOpeningDiscount = (monthlyFee * grandOpeningDiscountPercent) / 100;
          const couponDiscount = (parseInt(couponDetails?.amount_off) / 100)
          const familyDiscount = (familyDiscountPercent / 100) * (parseInt(monthlyFee) - (grandOpeningDiscount))
          return ((parseInt(monthlyFee)) - (grandOpeningDiscount + couponDiscount + familyDiscount)).toFixed(2)
        } else {
          const grandOpeningDiscount = (monthlyFee * grandOpeningDiscountPercent) / 100;
          const couponDiscount = (parseInt(couponDetails?.amount_off) / 100)
          return ((parseInt(monthlyFee)) - (grandOpeningDiscount + couponDiscount)).toFixed(2)
        }
      } else {
        const couponDiscount = parseInt(monthlyFee) - (parseInt(couponDetails?.amount_off) / 100)
        return ((parseInt(monthlyFee)) - couponDiscount).toFixed(2)
      }
    } else if (validCode && currentDate >= grandOpeningStart && currentDate <= grandOpeningEnd) {
      if (totalChildOfParent === 1) {
        const grandOpeningDiscount = (monthlyFee * grandOpeningDiscountPercent) / 100;
        const familyDiscount = (familyDiscountPercent / 100) * (parseInt(monthlyFee) - grandOpeningDiscount)
        return ((parseInt(monthlyFee)) - (grandOpeningDiscount + familyDiscount));
      } else if (totalChildOfParent > 1) {
        const grandOpeningDiscount = (monthlyFee * grandOpeningDiscountPercent) / 100;
        const familyDiscount = (familyDiscountPercent / 100) * (monthlyFee - grandOpeningDiscount)
        return ((parseInt(monthlyFee)) - (grandOpeningDiscount + familyDiscount)).toFixed(2);
      } else {
        return ((parseInt(monthlyFee)) - ((monthlyFee * grandOpeningDiscountPercent) / 100)).toFixed(2);
      }
    }
    else {
      return parseInt(monthlyFee);
    }
  }

  const onlineAndSpecializedTotalAmount = (totalChildOfParent: any) => {
    const courseFeeFloat = parseFloat(courseFee);
    const roundToTwoDecimals = (value: number) => Math.round(value * 100) / 100;
  
    if ((!validCode || !showCouponBox) && currentDate >= grandOpeningStart && currentDate <= grandOpeningEnd) {
      if (totalChildOfParent === 1) {
        const grandOpeningDiscount = roundToTwoDecimals((courseFeeFloat * grandOpeningDiscountPercent) / 100);
        const familyDiscount = roundToTwoDecimals((familyDiscountPercent / 100) * (courseFeeFloat - grandOpeningDiscount));
        return roundToTwoDecimals(courseFeeFloat - (grandOpeningDiscount + familyDiscount));
      } else if (totalChildOfParent > 1) {
        const grandOpeningDiscount = roundToTwoDecimals((courseFeeFloat * grandOpeningDiscountPercent) / 100);
        const familyDiscount = roundToTwoDecimals((familyDiscountPercent / 100) * (courseFeeFloat - grandOpeningDiscount));
        return roundToTwoDecimals(courseFeeFloat - (grandOpeningDiscount + familyDiscount));
      } else {
        return roundToTwoDecimals(courseFeeFloat - ((courseFeeFloat * grandOpeningDiscountPercent) / 100));
      }
    } else if (validCode && couponDetails?.percent_off) {
      if (currentDate >= grandOpeningStart && currentDate <= grandOpeningEnd) {
        if (totalChildOfParent >= 1) {
          const grandOpeningDiscount = roundToTwoDecimals((courseFeeFloat * grandOpeningDiscountPercent) / 100);
          const couponDiscount = roundToTwoDecimals((couponDetails?.percent_off / 100) * (courseFeeFloat - grandOpeningDiscount));
          const familyDiscount = roundToTwoDecimals((familyDiscountPercent / 100) * (courseFeeFloat - (grandOpeningDiscount + couponDiscount)));
          return roundToTwoDecimals(courseFeeFloat - (grandOpeningDiscount + couponDiscount + familyDiscount));
        } else {
          const grandOpeningDiscount = roundToTwoDecimals((courseFeeFloat * grandOpeningDiscountPercent) / 100);
          const couponDiscount = roundToTwoDecimals((couponDetails?.percent_off / 100) * (courseFeeFloat - grandOpeningDiscount));
          return roundToTwoDecimals(courseFeeFloat - (grandOpeningDiscount + couponDiscount));
        }
      } else {
        const couponDiscount = roundToTwoDecimals((couponDetails?.percent_off / 100) * courseFeeFloat);
        return roundToTwoDecimals(courseFeeFloat - couponDiscount);
      }
    } else if (validCode && couponDetails?.amount_off) {
      if (currentDate >= grandOpeningStart && currentDate <= grandOpeningEnd) {
        if (totalChildOfParent >= 1) {
          const grandOpeningDiscount = roundToTwoDecimals((courseFeeFloat * grandOpeningDiscountPercent) / 100);
          const couponDiscount = roundToTwoDecimals(parseInt(couponDetails?.amount_off) / 100);
          const familyDiscount = roundToTwoDecimals((familyDiscountPercent / 100) * (courseFeeFloat - grandOpeningDiscount));
          return roundToTwoDecimals(courseFeeFloat - (grandOpeningDiscount + couponDiscount + familyDiscount));
        } else {
          const grandOpeningDiscount = roundToTwoDecimals((courseFeeFloat * grandOpeningDiscountPercent) / 100);
          const couponDiscount = roundToTwoDecimals(parseInt(couponDetails?.amount_off) / 100);
          return roundToTwoDecimals(courseFeeFloat - (grandOpeningDiscount + couponDiscount));
        }
      } else {
        const couponDiscount = roundToTwoDecimals(courseFeeFloat - (parseInt(couponDetails?.amount_off) / 100));
        return roundToTwoDecimals(courseFeeFloat - couponDiscount);
      }
    } else if (validCode && currentDate >= grandOpeningStart && currentDate <= grandOpeningEnd) {
      if (totalChildOfParent === 1) {
        const grandOpeningDiscount = roundToTwoDecimals((courseFeeFloat * grandOpeningDiscountPercent) / 100);
        const familyDiscount = roundToTwoDecimals((familyDiscountPercent / 100) * (courseFeeFloat - grandOpeningDiscount));
        return roundToTwoDecimals(courseFeeFloat - (grandOpeningDiscount + familyDiscount));
      } else if (totalChildOfParent > 1) {
        const grandOpeningDiscount = roundToTwoDecimals((courseFeeFloat * grandOpeningDiscountPercent) / 100);
        const familyDiscount = roundToTwoDecimals((familyDiscountPercent / 100) * (courseFeeFloat - grandOpeningDiscount));
        return roundToTwoDecimals(courseFeeFloat - (grandOpeningDiscount + familyDiscount));
      } else {
        return roundToTwoDecimals(courseFeeFloat - ((monthlyFee * grandOpeningDiscountPercent) / 100));
      }
    } else {
      return roundToTwoDecimals(courseFeeFloat);
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
        color: '#000000',
        letterSpacing: '0.025em',
        fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
        '::placeholder': {
          color: '#3f3e3e',
        },
        height: '50px',
        padding: '14px',
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
      className="payment-form"
      onValuesChange={handleFormChange}
    >
      <ATitle level={4} content="Billing Details" />
      <ATitle level={5} content="Personal Details" />
      <Row wrap gutter={20}>
        <Col md={24} sm={24} lg={24} xs={24}>
          <Form.Item name="personal_information" valuePropName="checked">
            <Checkbox
              checked={personalInfoCheckbox}
              onChange={handlePersonalInfoCheckboxChange}
            // style={{ fontSize: "1rem" }}
            >
              Same as parent/guardian's details provided on the previous screen
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>
      <Row wrap gutter={20}>
        <Col md={12} sm={24} lg={12} xs={24}>
          <Form.Item label="First Name" name="owner_first_name" required>
            <AInput
              className="custom-input"
              placeholder="Owner's First Name"
              disabled={personalInfoCheckbox}
            />
          </Form.Item>
        </Col>

        {/* <Col md={12} sm={24} lg={8} xs={24}>
          <Form.Item
            label="Middle Name"
            name="owner_middle_name"
            help="Only the initial letter!"
          >
            <AInput
              disabled={personalInfoCheckbox}
              placeholder="Owner's Middle Name"
              maxLength={1}
            />
          </Form.Item>
        </Col> */}

        <Col md={12} sm={24} lg={12} xs={24}>
          <Form.Item label="Last Name" name="owner_last_name" required>
            <AInput
              className="custom-input"
              placeholder="Owner's Last Name"
              disabled={personalInfoCheckbox}
            />
          </Form.Item>
        </Col>

        <Col md={12} sm={24} lg={12} xs={24}>
          <Form.Item label="Email" name="email" required>
            <AInput
              className="custom-input"
              disabled={personalInfoCheckbox}
              placeholder="Owner's Email"
            />
          </Form.Item>
        </Col>
      </Row>

      {/* <Row wrap gutter={20}> */}
      {/* <Col md={12} sm={24} lg={8} xs={24}>
          <Form.Item label="Admission Fee" name="admission_fee">
            <AInput prefix={"$"} defaultValue={admissionFee} disabled />
          </Form.Item>
        </Col>
        <Col md={12}>
          <Form.Item label="Advance Payment" name="advance_payment" help="This payment will be deducted when the first subscription is made!!!">
          <AInput defaultValue={40} disabled />
          </Form.Item>
          </Col>
        <Col md={12} sm={24} lg={8} xs={24}>
          <Form.Item
            label="Monthly Fee"
            name="monthly_fee"
            help="This fee will be charged automatically at the starting of each month!!!"
            >
            <AInput prefix={"$"} defaultValue={monthlyFee} disabled />
          </Form.Item>
        </Col> */}
      {/* </Row> */}

      <ATitle level={5} content="Billing Address" />
      <Row wrap gutter={20}>
        <Col md={24} sm={24} lg={24} xs={24}>
          <Form.Item name="billing_address" valuePropName="checked">
            <Checkbox
              checked={billingAddressCheckbox}
              onChange={handleBillingAddressCheckboxChange}
            // style={{ fontSize: "1rem" }}
            >
              Same as parent/guardian's details provided on the previous screen
            </Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row wrap gutter={20}>
        <Col md={12} sm={24} lg={12} xs={24}>
          <Form.Item
            name="address1"
            label="Address Line 1"
            rules={[
              {
                required: true,
                message: "Please input address line 1!",
              },
            ]}
          >
            <AInput
              className="custom-input"
              disabled={billingAddressCheckbox}
              placeholder="Enter Address Line 1"
            />
          </Form.Item>
        </Col>
        <Col md={12} sm={24} lg={12} xs={24}>
          <Form.Item name="address2" label="Address Line 2">
            <AInput
              className="custom-input"
              disabled={billingAddressCheckbox}
              placeholder="Enter Address Line 2"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row wrap gutter={20}>
        <Col md={12} sm={24} lg={12} xs={24}>
          <Form.Item
            name="city"
            label="City"
            rules={[
              {
                required: true,
                message: "Please input your city name!",
              },
            ]}
          >
            <AInput
              className="custom-input"
              disabled={billingAddressCheckbox}
              placeholder="Enter City Name"
            />
          </Form.Item>
        </Col>
        <Col md={12} sm={24} lg={12} xs={24}>
          <Form.Item
            name="state"
            label="State"
            rules={[
              {
                required: true,
                message: "Please select your state!",
              },
            ]}
          >
            <ASelect
              className="custom-input"
              allowClear
              maxLength={1}
              placeholder="Select Your State"
              disabled={billingAddressCheckbox}
              customized={false}
              maxTagCount={1}
            >
              {states &&
                states.map((state: { label: string; value: string }) => {
                  return (
                    <Option value={state.value} key={state.value}>
                      {state.label}
                    </Option>
                  );
                })}
            </ASelect>
          </Form.Item>
        </Col>
      </Row>

      <Row wrap gutter={20}>
        <Col md={12} sm={24} lg={12} xs={24}>
          <Form.Item
            name="zip_code"
            label="Zip Code"
            rules={[
              {
                required: true,
                message: "Please input zip code!",
              },
              {
                pattern: /^\d{5}$/,
                message: "Zip code must be exactly 5 digits!",
              },
            ]}
          >
            <AInput
              className="custom-input"
              disabled={billingAddressCheckbox}
              maxLength={5}
              placeholder="Enter Zip Code"
            />
          </Form.Item>
        </Col>
      </Row>

      <Row wrap gutter={20}>
        <Col md={24} sm={24} lg={24} style={{
          width: "100%"
        }}>
          <ATitle level={5} content="Card Details" />
          {cards.length != 0 && !cardInput ? (
            <>
              <Form.Item name="payment_method" initialValue={cards?.[0]?.id}>
                <ASelect customized={false} placeholder="Select payment card" disabled>
                  {cards.map((card: PaymentCardProps) => (
                    <Select.Option value={card.id}>
                      {`${card.brand}  xxxx-xxxx-xxxx-${card.last4} ${card.exp_month}/${card.exp_year}`}
                    </Select.Option>
                  ))}
                </ASelect>
              </Form.Item>
              <p
                onClick={() => {
                  setIsFormValid(false)
                  toggleCardInput(true);
                }}
              >
                Add New Payment Card
              </p>
            </>
          ) : (
            <>
              <Form.Item name="card">
                <CardElement options={cardElementOptions} />
              </Form.Item>
              {cards.length > 0 && (
                <p
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setIsFormValid(true)
                    toggleCardInput(false);
                  }}
                >
                  Use Existing Payment Card
                </p>
              )}
            </>
          )}
        </Col>
      </Row>

      {cards.length > 0 && (
        <Row wrap gutter={20}>
          <Col>
            <Form.Item name="defaultPaymentCard" valuePropName="checked">
              {/* <Checkbox onClick={() => setShowCouponBox(!showCouponBox) couponBox}> */}
              <Checkbox onClick={() => setDefaultCard(!defaultCard)}>
                Make this card your default payment card?
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
      )}

      <Row wrap gutter={20}>
        <Col>
          <Form.Item name="promo_code" valuePropName="checked">
            {/* <Checkbox onClick={() => setShowCouponBox(!showCouponBox) couponBox}> */}
            <Checkbox onClick={couponBox}>Have Promo/Discount Coupon?</Checkbox>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        {showCouponBox && (
          <Col md={24}>
            <ATitle level={5} content="Promo/Discount Coupons" />
            <FormItem
              name="coupons"
              rules={[
                {
                  required: true,
                  message: "Please input the coupon!!!",
                },
              ]}
            >

              <div style={{ display: "flex", gap: "1rem" }}>
                <AInput
                  customized={false}
                  onChange={(e) => {
                    setToken(e.target.value);
                  }}
                // onBlur={handlePromoChange}
                />
                <AButton
                  type="primary"
                  onClick={() =>
                    handlePromoChange({
                      target: { value: tokenData },
                    } as React.ChangeEvent<HTMLInputElement>)
                  }
                >
                  Apply
                </AButton>
              </div>
            </FormItem>
            {validCode && !validating && couponDetails ? (
              <>
                <div style={{
                  display: "flex",
                  gap: 30,
                  alignItems: "center"
                }}>
                  <p>
                    {couponDetails.name} :{" "}
                    {couponDetails.amount_off
                      ? ` $ ${couponDetails.amount_off / 100} `
                      : `${couponDetails.percent_off} % `}
                    off
                  </p>
                </div>
              </>
            ) : (
              <p style={{ color: "red" }}>{errorMessage.errors}</p>
            )}
          </Col>
        )}
      </Row>

      {!campForm &&
        <>
          <Row wrap gutter={20}>
            <Col>
              <Form.Item name="referral_box" valuePropName="checked">
                <Checkbox onClick={referralBox}>Were you referred by a current customer?</Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            {showReferralBox && (
              <Col md={24}>
                <ATitle level={5} content="Referral Email" />
                <FormItem
                  name="referral"
                  rules={[
                    {
                      required: true,
                      message: "Please input referral email!!!",
                    },
                  ]}
                >

                  <div style={{ display: "flex", gap: "1rem" }}>
                    <AInput
                      customized={false}
                      onChange={(e) => {
                        setToken(e.target.value);
                      }}
                    // onBlur={handlePromoChange}
                    />
                    <AButton
                      type="primary"
                      onClick={() =>
                        handleReferralChange({
                          target: { value: tokenData },
                        } as React.ChangeEvent<HTMLInputElement>)
                      }
                    >
                      Validate
                    </AButton>
                  </div>
                </FormItem>
                {referral && !validatingReferral ? (
                  <p>
                    The provided email belongs to an active customer. Both you and the referrer will receive a $50 credit, which will be applied to next month’s tuition fee.
                  </p>
                ) : (
                  <p style={{ color: "red" }}>{referralErrorMessage}</p>
                )}
              </Col>
            )}
          </Row>
        </>
      }
      <Row>
        <Col sm={24} xs={24} md={24} lg={24}>
          <img
            src={payment}
            alt=""
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Col>
      </Row>

      {studentCount < 25 && (
        <>
          {showBillDetails && (
            <Row>
              <Col md={24}>
                <ATitle level={5} content="Details" />
                <Card>
                  {campForm && !dailyCamp ? (
                    <>
                      <p>
                        One-Time Camp Fee: ${currentDate >= winterCampStart && currentDate <= winterCampEnd
                          ? monthlyFee
                          : monthlyFee}
                      </p>
                      {!validCode &&
                        <p>
                          Discount Amount: $
                          {
                            `${calculateCampDiscountWithoutValidCoupon(totalChildOfParent)}`
                          }
                          {/* ${
                  calculateCampDiscount(totalChildOfParent)
                    totalChildOfParent === 1
                    ? (parseInt(monthlyFee) - parseInt(monthlyFee) * 25 / 100) + " (Winter Camp 25% Off)"

                    : parseInt(monthlyFee) * 25 / 100 + " (Winter Camp 25% Off)"
                  
                  }  */}
                        </p>
                      }
                      {validCode && couponDetails?.percent_off &&
                        <>
                          <p>
                            Discount Amount: $
                            {
                              `${calculateCampDiscountWithValidCoupon(totalChildOfParent)}`
                            }
                            {/* {
                  currentDate >= winterCampStart &&
                  currentDate <= winterCampEnd 
                  ? ((monthlyFee * 25) / 100) + ((couponDetails?.percent_off / 100) * (monthlyFee - (monthlyFee * 25) / 100))  + " (Winter Camp 25% Off + Coupon Discount)"
                  : 
                  (couponDetails?.percent_off / 100) * monthlyFee
                } */}
                          </p>
                          <p>Total Amount: $
                            {`${calculateCampTotalAmountWithValidCoupon(totalChildOfParent)}`}
                            {/* {currentDate >= winterCampStart && currentDate <= winterCampEnd
                    ? monthlyFee - parseInt(monthlyFee) * 25 / 100 - ((couponDetails?.percent_off / 100) * (monthlyFee - parseInt(monthlyFee) * 25 / 100))
                    : monthlyFee} */}
                          </p>
                        </>
                      }
                      {validCode && couponDetails?.amount_off &&
                        <>
                          <p>
                            Discount Amount: $
                            {
                              `${calculateCampDiscountWithValidCouponAmountOff(totalChildOfParent)}`
                            }
                            {/* {
                  currentDate >= winterCampStart &&
                  currentDate <= winterCampEnd 
                  ? ((monthlyFee * 25) / 100) + ((couponDetails?.percent_off / 100) * (monthlyFee - (monthlyFee * 25) / 100))  + " (Winter Camp 25% Off + Coupon Discount)"
                  : 
                  (couponDetails?.percent_off / 100) * monthlyFee
                } */}
                          </p>
                          <p>Total Amount: $
                            {`${calculateCampTotalAmountWithValidCouponAmountOff(totalChildOfParent)}`}
                            {/* {currentDate >= winterCampStart && currentDate <= winterCampEnd
                    ? monthlyFee - parseInt(monthlyFee) * 25 / 100 - ((couponDetails?.percent_off / 100) * (monthlyFee - parseInt(monthlyFee) * 25 / 100))
                    : monthlyFee} */}
                          </p>
                        </>
                      }
                      {
                        !validCode &&
                        <p>Total Amount: $
                          {`${calculateCampTotalAmountWithoutValidCoupon(totalChildOfParent)}`}
                          {/* {currentDate >= winterCampStart && currentDate <= winterCampEnd
                    ? monthlyFee - parseInt(monthlyFee) * 25 / 100
                    : monthlyFee} */}
                        </p>
                      }
                    </>
                  ) : campForm && dailyCamp ? (
                    <>
                      <p>
                        One-Time Camp Fee: ${currentDate >= winterCampStart && currentDate <= winterCampEnd
                          ? monthlyFee * selectedDates
                          : monthlyFee * selectedDates}
                      </p>
                      {!validCode &&
                        <p>
                          Discount Amount: $
                          {`${calculateDailyCampDiscountWithoutValidCoupon(totalChildOfParent)}`}
                          {/* {parseInt(monthlyFee) * selectedDates * 25 / 100 + " (Winter Camp 25% Off)"}  */}
                        </p>
                      }
                      {validCode && couponDetails?.percent_off &&
                        <>
                          <p>
                            Discount Amount: $
                            {`${calculateDailyCampDiscountWithValidCoupon(totalChildOfParent)}`}
                            {/* {
                currentDate >= winterCampStart &&
                currentDate <= winterCampEnd 
                ? ((monthlyFee * 25) * selectedDates / 100) + ((couponDetails?.percent_off / 100) * (monthlyFee * selectedDates - (monthlyFee * 25) * selectedDates / 100))  + " (Winter Camp 25% Off + Coupon Discount)"
                : 
                (couponDetails?.percent_off / 100) * monthlyFee * selectedDates
              } */}
                          </p>
                          <p>Total Amount: $
                            {`${calculateDailyCampTotalAmountWithValidCoupon(totalChildOfParent)}`}
                            {/* {currentDate >= winterCampStart && currentDate <= winterCampEnd
                  ? monthlyFee * selectedDates - parseInt(monthlyFee) * selectedDates * 25 / 100 - ((couponDetails?.percent_off / 100) * (monthlyFee * selectedDates - parseInt(monthlyFee) * selectedDates * 25 / 100))
                  : monthlyFee * selectedDates} */}
                          </p>
                        </>
                      }
                      {
                        !validCode &&
                        <p>Total Amount: $
                          {`${calculateDailyCampTotalAmountWithoutValidCoupon(totalChildOfParent)}`}
                        </p>
                      }
                    </>
                  )
                    : (
                      <>
                        <p>
                          {
                            (programType?.toLowerCase() === "online" || programType?.toLowerCase() === "specialized") ? 
                            (
                              <>
                              One-Time Course Fee: ${courseFee}
                              </>
                            ) : (
                              <>
                                Monthly Fee: ${monthlyFee}
                              </>
                            )
                          }
                        </p>
                        <p>Discount:
                          ${(programType?.toLowerCase() === "online" || programType?.toLowerCase() === "specialized") ? onlineAndSpecializedCourseDiscount(totalChildOfParent) : monthlyFeeDiscount(totalChildOfParent)}
                        </p>
                        <p>Total Amount: ${(programType?.toLowerCase() === "online" || programType?.toLowerCase() === "specialized") ? onlineAndSpecializedTotalAmount(totalChildOfParent) : monthlyFeeTotalAmount(totalChildOfParent)}</p>
                      </>
                    )}
                </Card>
              </Col>
            </Row>
          )}
        </>
      )}

      <Row style={{ marginTop: "2vh" }}>
        <Col md={8}>
          {studentCount < 25 ? (
            <AButton
              type="primary"
              htmlType="submit"
              disabled={disablePayButton()}
            >
              Pay
            </AButton>
          ) : (
            <AButton type="primary" htmlType="submit">
              Submit
            </AButton>
          )}
        </Col>
      </Row>
    </Form>
  );
};

export default function CheckoutForm({
  selectedPlans,
  onFinish,
  newChild,
  admissionFee,
  monthlyFee,
  studentCount,
  formValues,
  userDetails,
  id,
  parent_id,
  campForm,
  dailyCamp,
  selectedDates,
  campToMonth,
  programType,
  courseFee,
}: PaymentFormProps) {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm
        selectedPlans={selectedPlans}
        onFinish={onFinish}
        newChild={newChild}
        admissionFee={admissionFee}
        monthlyFee={monthlyFee}
        studentCount={studentCount}
        formValues={formValues}
        userDetails={userDetails}
        id={id}
        parent_id={parent_id}
        campForm={campForm}
        dailyCamp={dailyCamp}
        selectedDates={selectedDates}
        campToMonth={campToMonth}
        programType={programType}
        courseFee={courseFee}
      />
    </Elements>
  );
}
